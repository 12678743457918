import {
    SET_TRANSLATIONS_LOADED,
    SET_MESSAGE
} from "store/actionTypes";


export const setTranslationsLoaded = () => ({
	type: SET_TRANSLATIONS_LOADED,
});

export const setMessage = message => ({
	type: SET_MESSAGE,
    payload: { message }
});