import React from 'react';
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import Input from 'components/ui/input';

/** Editable Amount Component */
const EditableAmount = ( {
    value, 
    onChange
} ) => {

    const { t } = useTranslation();

    const handleAmountInputChange = v => {
        const reg = new RegExp('^[0-9]*$');

        if (!isNaN(v) && reg.test(v) || v === '') {
            onChange?.(v)
        }
    }

    return (
        <div 
            className="rt--form-item rt--form-item-without-margiin rt--general-form-item"
            data-placeholder={`${t('widget.common.enter')} ${t('widget.common.amount')}`}
        >
            <div className="rt--form-item-content">
                <div className="rt--form-item-label">
                    <label>{t('widget.common.amount')}</label>
                </div>
                <Input
                    placeholder={`${t('widget.common.enter')} ${t('widget.common.amount')}`}
                    onChange={e => handleAmountInputChange(e.target.value)}
                    value={value}
                />
            </div>
        </div>
    )
}

/** EditableAmount propTypes
    * PropTypes
*/
EditableAmount.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func
}


export default EditableAmount;