export default {
    common: {
		message: null,
        translationsLoaded: false,
	},
	auth: {
		sessionLoaded: false,
		sessionExpired: false,
		session: {},
		info: {}
	},
	banks: {
		isLoading: false,
		banks: [],
		form: {
			isLoading: false,
			forms: [],
			translations: []
		},
		details: {
			isLoading: false,
			details: {}
		},
		error: null
	},
	epos: {
		isLoading: false,
		agents: [],
		selectedAgentId: null
	},
	transaction: {
		isLoading: false,
		data: null,
		retry: null
	}
}
