import {
    SET_EPOS_ACTION_BEFORE,
    SET_EPOS_ACTION_FINISH,
    SET_EPOS_AGENTS,
    SET_EPOS_SELECTED_AGENT_ID
} from "store/actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_EPOS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            }
        case SET_EPOS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false
            }
        case SET_EPOS_AGENTS:
            return {
                ...state,
                agents: payload.agents
            }
        case SET_EPOS_SELECTED_AGENT_ID:
            return {
                ...state,
                selectedAgentId: payload.agentId
            }
        default:
            return state
    }
}