import i18n from 'translations/config';

import MessageUtils from './message';

export const getSystemUrls = () => {
    const currentOrigin = window.location.origin;

    if(import.meta.env.SYSTEM_APP_MODE === "development"){
        return {
            API: import.meta.env.SYSTEM_API_URL,
            CDN: import.meta.env.SYSTEM_CDN_URL,
        }
    }

    if (currentOrigin === "https://widget.moqetaa.space") {
        return {
            API: import.meta.env.SYSTEM_MOQETAA_API_URL,
            CDN: import.meta.env.SYSTEM_MOQETAA_CDN_URL,
        }
    } else {
        return {
            API: import.meta.env.SYSTEM_API_URL,
            CDN: import.meta.env.SYSTEM_CDN_URL,
        }
    }
}  

/** Check if device is mobile
     * @function
     * @returns {boolean}
 */
export const isMobile = () => {

    const urlParams = new URLSearchParams(window.location.search);

    const isMobileBrowser = {
        Android: () => navigator.userAgent.match(/Android/i) !== null,
        BlackBerry: () => navigator.userAgent.match(/BlackBerry/i) !== null,
        iOS: () => navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null || (navigator.userAgent.includes("Mac") && "ontouchend" in document),
        Opera: () => navigator.userAgent.match(/Opera Mini/i) !== null,
        Windows: () => navigator.userAgent.match(/IEMobile/i) !== null || navigator.userAgent.match(/WPDesktop/i) !== null,
        any: () => isMobileBrowser.Android() || isMobileBrowser.BlackBerry() || isMobileBrowser.iOS() || isMobileBrowser.Opera() || isMobileBrowser.Windows()
    };
    return isMobileBrowser.any() || urlParams.get("isMobile") === "true";
};

/** Make first letter of string to lower case 
     * @function
     * @param {string} str - string to convert
     * @returns {string}
 */
export const toLowerCaseFirstLetter = str => str.charAt(0).toLowerCase() + str.slice(1)

/** Make first letter of string to upper case 
     * @function
     * @param {string} str - string to convert
     * @returns {string}
 */
export const toUpperCaseFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1)

/** Check if device is ios
	  * @function
	  * @returns {boolean}
 */
export const isIOS = () => navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null;

export const IOSversion = () => {
    if (/iP(hone|od|ad)/.test(window.navigator.userAgent)) {
        const versionInfoArr = window.navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
        return {
            fullName: versionInfoArr[0],
            version: parseInt(versionInfoArr[1]) || 0,
            subBersion: parseInt(versionInfoArr[2]) || 0,
            fixVersion: parseInt(versionInfoArr[3]) || 0,
        }
    }
}

/** Beutify number by adding spaces
     * @function
     * @param {number} num
     * @returns {string}
 */
export const numberWithSpaces = num => {
    if (num === null || num === undefined) return "";
    const parts = num.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
}

/** Copy text to clipboard 
     * @function
     * @param {string} text - text to copy
     * @returns {string}
*/

export const copyToClipboard = text => {
    const input = document.createElement('textarea');
    input.innerHTML = text;
    document.body.appendChild(input);
    input.select();
    const result = document.execCommand('copy');
    document.body.removeChild(input);
    MessageUtils.success(i18n.t('widget.common.copied'));
    return result;
}

/** Check if preview mode
     * @function
     * @returns {boolean}
 */
export const isPreviewMode = () => {

    const urlParams = new URLSearchParams(window.location.search);

    return urlParams.get("isPreview") === "true";
};

/** Check if preview mode for agent
     * @function
     * @returns {boolean}
 */
export const isPreviewModeForAgent = () => {

    const urlParams = new URLSearchParams(window.location.search);

    return urlParams.get("isPreview") === "true" && urlParams.get("fromAgent") === "true";
};

/** Cheks if the page is RTL
      * @function
      * @param {string} language - the language
      * @returns {boolean}
 */
 export const isRTL = language => {
    const farsiLanguages = ['FA', 'fa', 'ar', 'AR'];
    return farsiLanguages.includes(language);
}

/** Get query params
     * @function
     * @param {string} path - location path
     * @returns {object} - query params
 */
 export const getUrlVars = path => {
    const href = path || window.location.href;
    const vars = {};
    href.replace(/[?&]+([^=&]+)=([^&]*)/gi,
        (m, key, value) => {
            vars[key] = value ? value.split("#")[0] : value;
        }
    );
    return vars;
}


/** Function to generate telegram link from phone number or username
	 * @function
     * @param {string} contact
	 * @returns {string} 
 */
export const generateTelegramLink = contact => {
    if(!contact) return "";
    return `https://t.me/${contact}`;
}

/** Function to generate WhatsApp link from phone number or username
	 * @function
     * @param {string} contact
	 * @returns {string} 
 */
export const generateWhatsAppLink = contact => {
    if(!contact) return "";
    return `https://wa.me/${contact}`;
}