import axios from 'axios';

import store from 'store/configureStore';

import { setSessionExpired } from "store/actions/auth.actions";

import { initTranslations } from 'translations/config'

import MessageUtils from 'utils/message';
import { getLanguage } from 'utils/auth';
import { getSystemUrls } from 'utils/common';

import { RESPONSE_CODES } from 'constants/errorCode.constants';
import ApiUrls from "constants/api.constants";

axios.defaults.baseURL = getSystemUrls().API;

axios.interceptors.request.use( config => {
    
    // if(config.method === "get"){
    //     config.url = config.url.indexOf("?") > -1 ? config.url + "&timestump=" + Date.now() : config.url + "?timestump=" + Date.now()
    // }

    if (config?.headers?.grant_type !== 'refresh_token') {
        const token = store.getState()?.auth?.session?.token ?? null;
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }

        //Set default content type
        if (!config.headers['Content-Type'] || config.headers['Content-Type'] === 'text/plain;charset=utf-8') {
            config.headers['Content-Type'] = 'application/json;charset=utf-8';
        }

    } else {
        delete config.headers['grant_type'];
    }

    // Trim params and data

    if(config.params){
        const newParams = {}
        Object.keys(config.params).map(key => {
            if(typeof config.params[key] === "string"){
                if(key && typeof key === "string" && !key.toLowerCase().includes("password")){
                    newParams[key] = config.params[key].trim();
                } else {
                    newParams[key] = config.params[key]
                }
                
                if(newParams[key] === ""){
                    newParams[key] = null
                }
            } else {
                newParams[key] = config.params[key]
            }
        })
    }
    if(config.data && !(config.data instanceof FormData)){
        try{
            const newData = {};
            let parsed = config.data;
            if(typeof config.data === 'string'){
                try{
                    parsed = JSON.parse(config.data);
                }catch(ex){console.log("err")}
            }

            Object.keys(parsed).map(key => {
                if(typeof parsed[key] === "string"){
                    if(key && typeof key === "string" && !key.toLowerCase().includes("password")){
                        newData[key] = parsed[key].trim();
                    } else {
                        newData[key] = parsed[key]
                    }
                    if(newData[key] === ""){
                        newData[key] = null
                    }
                } else {
                    newData[key] = parsed[key]
                }
            })
            config.data = newData;
        }catch(ex){console.log(ex)}
    }
    
    return config;
}, error => {
    return Promise.reject(error);
});

axios.interceptors.response.use( ({ status , data }) => {
    if(data?.resultCode !== RESPONSE_CODES.Success && !(data instanceof Blob) && !(data instanceof Array) ){
        MessageUtils.showError( data?.message ?? '', data?.resultCode ?? 0, data?.resource ?? '');
    }
    return { status, data };
}, error => {
    if(!error.response || !error.response.data){
        return Promise.reject(error);
    }
    let status = error.response.status;
    let errorData = error.response.data;
    let requestURL = error.response.request.responseURL;

    const isBlobResponseType = error.request.responseType === 'blob' && error.response.data instanceof Blob && error.response.data.type && error.response.data.type.toLowerCase().indexOf('json') != -1
    
    if(status >= 400) {
        if(errorData?.resultCode === RESPONSE_CODES.Unauthorized || isBlobResponseType){ 
            store.dispatch(setSessionExpired())
        } else {
            if(
                !requestURL.includes(ApiUrls.LAUNCH_WIDGET) && 
                !requestURL.includes(ApiUrls.AUTHENTICATE) &&
                !requestURL.includes(ApiUrls.BANK_TRANSFER_DEPOSIT) &&
                !requestURL.includes(ApiUrls.BANK_TRANSFER_WITHDRAWAL) &&
                !requestURL.includes(ApiUrls.BANK_TRANSFER_DEPOSIT_FREEZE) &&
                !requestURL.includes(ApiUrls.BANK_TRANSFER_WITHDRAWAL_FREEZE) &&
                !requestURL.includes(ApiUrls.EPOS_WITHDRAWAL) &&
                !requestURL.includes(ApiUrls.BANK_DETAILS) && 
                !requestURL.includes(ApiUrls.RETRY_TRANSACTION)
            ){
                setTimeout(() => {
                    if (store.getState().common.translationsLoaded) {
                        MessageUtils.showError( errorData?.message ?? '', errorData?.resultCode ?? 0, errorData?.resource ?? '');
                    } else {
                        initTranslations(
                            null,
                            getLanguage(),
                            () => {
                                MessageUtils.showError( errorData?.message ?? '', errorData?.resultCode ?? 0, errorData?.resource ?? '');
                            }
                        );
                    }
                }, 200)
            }
            
            
        } 
    }
    return Promise.reject(error);
});
