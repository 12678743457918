import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { isMobile, isIOS, IOSversion } from 'utils/common';

/** Input Component */

const Input = ({
	value,
	placeholder,
	type="text",
    className="",
	...rest
}) => {

	const inputRef = useRef(null);

	const versionInfo = IOSversion();

    const fixPlaceholderBug = isMobile() && isIOS() && versionInfo.version >= 16

    const inputValue = value ?? "";

	useEffect(() => {
		if (inputRef.current && fixPlaceholderBug) {
			if (inputValue !== '') {
				inputRef.current.classList.add("rt--hide-placeholder")
			} else {
				inputRef.current.classList.remove("rt--hide-placeholder")
			}
		}
	}, [inputValue])

	return (
        <input
            {...rest}
            value={inputValue}
            ref={inputRef}
            className={`rt--input ${className}`}
            placeholder={fixPlaceholderBug ? null : placeholder}
        />
    )
}

/** Input propTypes
	* PropTypes
*/

Input.propTypes = {
	/** Input value */
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	/** Input placeholder */
	placeholder: PropTypes.string,
	/** Input type */
	type: PropTypes.string,
    /** Class name */
    className: PropTypes.string
}

export default Input;