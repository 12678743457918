import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';

import PropTypes from "prop-types";

/** Drawer Component */
const Drawer = ({
    title,
    children,
    visible,
    className="",
    onClose
}) => {

    const [internalVisible, setInternalVisible] = useState(false);

    useEffect(() => {
        setInternalVisible(visible)
    }, [visible])

    return createPortal(
        (
            <div className={'rt--drawer' + (internalVisible ? " rt--drawer-open " : "") + className}>
                <div className='rt--drawer-mask' onClick={onClose}></div>
                <div className='rt--drawer-wrapper'>
                    <div className='rt--drawer-content'>
                        <div className='rt--drawer-content-inner'>
                            <div className='rt--drawer-header'>
                                <div className='rt--drawer-header-title'>
                                    { title }
                                </div>
                            </div>
                            <div className='rt--drawer-body'>
                                { children }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ),
        document.getElementsByClassName("rt--container")[0]
    )
}

/** Drawer propTypes
	* PropTypes
*/

Drawer.propTypes = {
    /** Title */
    title: PropTypes.string,
    /** Content */
    children: PropTypes.node,
    /** Is visible */
    visible: PropTypes.bool,
    /** Class name */
    className: PropTypes.string,
    /** Function fires on close */
    onClose: PropTypes.func
};

export default Drawer;