import React from 'react';
import PropTypes from "prop-types";

import BankItem from "./bankItem";

const BanksList = ({
    banks, 
    value, 
    onChange
}) => {
    const handleChange = (key) => {
        onChange(key);
    }

    if (!banks || banks.length === 0) {
        return <div>No Bank</div>;
    }

    return (

            banks.map(bank => (
                <BankItem
                    key={bank.id}
                    bank={bank}
                    isSelected={value === bank.id}
                    onSelect={handleChange}
                />
            ))
    );
};

BanksList.propTypes = {
    banks: PropTypes.array,
    value: PropTypes.string,
};

export default BanksList;
