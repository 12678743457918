import React from 'react';
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

const Error = ({error, info}) => {
    const { t } = useTranslation();

    if (!error) {
        return null;
    }

    return error.resource && (
        <div className='rt--flex rt--main-content-error rt--mb-4'>
            <i className='icon-alert rt--font-biger rt--font-error'/>
            <span className='rt--title rt--font-regular rt--font-small rt--font-error rt--ml-4'>
                {
                    error.resource === "Currency" ? t("widget.common.theCurrencyIsNotSupported") :
                        error.resource === "MaxAmount" ? t("widget.common.theMaxAmountOfRequestShouldBe").replace("%%X%%", error.maxAmount + " " + info.currencyCode) :
                            error.resource === "ShouldHaveDeposit" ? t("widget.common.shouldHaveDeposit") :
                                t("widget.common.agentNotFound")
                }
            </span>
        </div>
    )
};

Error.propTypes = {
    error: PropTypes.object,
    info: PropTypes.object,
}

export default Error;
