import axios from "axios";

import ApiUrls from "constants/api.constants";
import Methods from "constants/httpMethods.constants";

import i18n from "translations/config"

import { getLanguage } from "utils/auth";

import { setMessage } from "store/actions/common.actions";

import {
    SET_EPOS_ACTION_BEFORE,
    SET_EPOS_ACTION_FINISH,
    SET_EPOS_AGENTS,
    SET_EPOS_SELECTED_AGENT_ID
} from "store/actionTypes";

const setEposActionBefore = () => ({
    type: SET_EPOS_ACTION_BEFORE,
});

const setEposActionFinished = () => ({
    type: SET_EPOS_ACTION_FINISH,
});

export const setEposSelectedAgentId = agentId => ({
    type: SET_EPOS_SELECTED_AGENT_ID,
    payload: { agentId },
});

const setEposAgents = agents => ({
    type: SET_EPOS_AGENTS,
    payload: { agents },
});

export const getEposAgents = amount => {
    return ( dispatch, getState) => {
        
        dispatch(setEposActionBefore());

        return axios({
            url: ApiUrls.EPOS_AGENTS,
            method: Methods.GET,
            params: {
                languageCode: getLanguage(),
                amount
            },
        })
            .then(({ data }) => {
                let agents = data.value.item2;
                let maxAmount = data.value.item1;
                if(agents.length > 0){
                    dispatch(setEposAgents(agents));
                } else {
                    if(!maxAmount){
                        dispatch(setMessage({
                            type: "error",
                            message: i18n.t("widget.common.thereIsNoEposForWithdrawal"),
                        }))
                    } else {
                        const info = getState().auth.info
                        dispatch(setMessage({
                            type: "error",
                            message: i18n.t("widget.common.theMaxAmountOfRequestShouldBe").replace("%%X%%", maxAmount + " " + info.currencyCode),
                        }))
                    }
                    
                }
                dispatch(setEposActionFinished());
            })
            .catch((ex) => {
                dispatch(setEposActionFinished());
            });
    }
}

export const getEposAgent = agentId => {
    return dispatch => {
        
        dispatch(setEposActionBefore());

        return axios({
            url: ApiUrls.EPOS_AGENT,
            method: Methods.GET,
            params: {
                languageCode: getLanguage(),
                agentId
            },
        })
            .then(({ data }) => {
                let agent = data.value;
                dispatch(setEposAgents([agent]));
                dispatch(setEposActionFinished());
            })
            .catch((ex) => {
                dispatch(setEposActionFinished());
            });
    }
}