const PATHS = {
    LAUNCH_WIDGET: "/Account/Launch",
    AUTHENTICATE: "/Account/Authenticate",
    INFO: "/Payment/Info",
    BANKS: "/Payment/Banks",
    BANK_FORM: "/Payment/BankForm",
    BANK_DETAILS: "/Payment/BankTransferAgent",
    BANK_AGENT_DETAILS: "/Payment/BankAgentDetails",
    EPOS_AGENTS: "/Payment/EposAgents",
    EPOS_AGENT: "/Payment/EposAgent",
    BANK_TRANSFER_DEPOSIT: "/Transaction/BankTransferDeposit",
    BANK_TRANSFER_DEPOSIT_FREEZE: "/Transaction/BankTransferDepositFreeze",
    BANK_TRANSFER_WITHDRAWAL: "/Transaction/BankTransferWithdrawal",
    BANK_TRANSFER_WITHDRAWAL_FREEZE: "/Transaction/BankTransferWithdrawalFreeze",
    EPOS_WITHDRAWAL: "/Transaction/EposWithdrawal",
    EPOS_WITHDRAWAL_FREEZE: "/Transaction/EposWithdrawalFreeze",
    RETRY_TRANSACTION: "/Transaction/Retry"
}

export default PATHS;