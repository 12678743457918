import {
    SET_TRANSACTION_ACTION_BEFORE,
    SET_TRANSACTION_ACTION_FINISH,
    SET_TRANSACTION_DONE,
    SET_TRANSACTION_RETRY
} from "store/actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_TRANSACTION_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            }
        case SET_TRANSACTION_ACTION_FINISH:
            return {
                ...state,
                isLoading: false
            }
        case SET_TRANSACTION_DONE:
            return {
                ...state,
                data: payload.data,
                retry: null
            }
        case SET_TRANSACTION_RETRY:
            return {
                ...state,
                retry: payload.data
            }
        default:
            return state
    }
}