export class Version {
    constructor(version) {
        this.__version = this.__makeVersion(version);
    }

    get version() {
        return this.__version;
    }

    __makeVersion(version) {
        let [major = 0, minor = 0, patch = 0] = version.replace(/[^0-9.]/g, '').split('.');

        return `${major}.${minor}.${patch}`;
    }

    isEqual(version) {
        return this.version === version.version;
    }

    isGreaterThan(other) {
        const [major, minor, patch] = this.version.split('.').map(Number);
        const [otherMajor, otherMinor, otherPatch] = other.version.split('.').map(Number);

        if (major > otherMajor) {
            return true;
        }

        if (major === otherMajor) {
            if (minor > otherMinor) {
                return true;
            }

            if (minor === otherMinor) {
                if (patch > otherPatch) {
                    return true;
                }
            }
        }

        return false;
    }

    isLessThan(other) {
        const [major, minor, patch] = this.version.split('.').map(Number);
        const [otherMajor, otherMinor, otherPatch] = other.version.split('.').map(Number);

        if (major < otherMajor) {
            return true;
        }

        if (major === otherMajor) {
            if (minor < otherMinor) {
                return true;
            }

            if (minor === otherMinor) {
                if (patch < otherPatch) {
                    return true;
                }
            }
        }

        return false;
    }

    isGreaterThanOrEqualTo(version) {
        return this.isGreaterThan(version) || this.isEqual(version);
    }

    isLessThanOrEqualTo(version) {
        return this.isLessThan(version) || this.isEqual(version);
    }

    toString() {
        return this.version;
    }
}
