import React, { Fragment } from 'react';
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';

import { toUpperCaseFirstLetter, generateTelegramLink, generateWhatsAppLink } from 'utils/common';

import { SOCIAL_MEDIA_TYPE } from 'constants/common.constants';

import eposAgentType from 'types/eposAgent.type';

import TelegramLogo from 'assets/images/telegram.svg';
import WhatsAppLogo from 'assets/images/whatsApp.svg';

const makeTime = day => {
    let openHour = day?.openTime?.hour ?? 0;
    let openMinute = day?.openTime?.minute ?? 0;
    let closeHour = day?.closeTime?.hour ?? 0;
    let closeMinute = day?.closeTime?.minute ?? 0;

    openHour = openHour < 10 ? "0" + openHour : "" + openHour;
    openMinute = openMinute < 10 ? "0" + openMinute : "" + openMinute;
    closeHour = closeHour < 10 ? "0" + closeHour : "" + closeHour;
    closeMinute = closeMinute < 10 ? "0" + closeMinute : "" + closeMinute;

    return `${openHour}:${openMinute} - ${closeHour}:${closeMinute}`
}

/** Epos Details Component */
const EposDetails = ({
    agents,
    agentId
}) => {

    const { t } = useTranslation();

    /** Agent */
    const agent = agents.find(agent => agent.id === agentId);

    /** Working Hours */
    const weekDays = agent?.workingSchedule?.weekDays ?? {};

    /** Contacts */
    const contacts = agent?.contacts ?? [];

    /** Has Working Hours */
    const hasWorkingHours = Object.keys(weekDays.length > 0)

    const workingHours = Object.keys(weekDays).map(weekDay => ({
        key: weekDay,
        title: t("widget.common." + weekDay),
        value: makeTime(weekDays[weekDay])
    }))

    return (
        <Fragment>
            {
                hasWorkingHours && (
                    <div className='rt--info rt--pl-16 rt--pr-16 rt--pb-16 rt--pt-16 rt--mb-16'>
                        <span className='rt--title rt--info-title rt--font-bold rt--font-big'>{t("widget.common.workingHours")}</span>
                        <div className='rt--info-list rt--mt-12'>
                            {
                                workingHours.map(day => (
                                    <div className='rt--info-item rt--flex rt--align-center rt--justify-between rt--mb-8' key={day.key}>
                                        <span className='rt--title rt--font-regular rt--font-normal rt--pr-12'>{toUpperCaseFirstLetter(day.title)}:</span>
                                        <b className='rt--title rt--font-medium rt--font-normal'>{day.value}</b>
                                    </div>
                                ))
                            }
                        </div>
                        
                    </div>
                )
            }

            {
                contacts.map(contact => (
                    <div 
                        className='rt--info rt--pl-16 rt--pr-16 rt--pb-16 rt--pt-16 rt--mb-16' 
                        key={contact.socialMediaType}
                        onClick={
                            () => {
                                let link = null;
                                if(contact.socialMediaType === SOCIAL_MEDIA_TYPE.TELEGRAM){
                                    link = generateTelegramLink(contact.linkInfo);
                                } else if (contact.socialMediaType === SOCIAL_MEDIA_TYPE.WHATSAPP){
                                    link = generateWhatsAppLink(contact.linkInfo);
                                }
                                if(link){
                                    window.open(link, "_blank");
                                }
                            }
                        }
                    >
                        <div className='rt--info-contact rt--flex rt--flex-align'>
                            <img
                                className='rt--mr-16'
                                src={contact.socialMediaType === SOCIAL_MEDIA_TYPE.TELEGRAM ? TelegramLogo : WhatsAppLogo } 
                                alt={contact.socialMediaType} 
                            />
                            <div className='rt--flex rt--flex-col rt--justify-center'>
                                <span className='rt--title rt--font-regular rt--font-normal'>
                                    {
                                        contact.socialMediaType === SOCIAL_MEDIA_TYPE.TELEGRAM ? t("widget.common.telegram") : t("widget.common.whatsapp")
                                    }
                                </span>
                                <b className='rt--title rt--font-bold rt--font-big rt--pt-2'>{contact.linkInfo}</b>
                            </div>
                        </div>
                    </div>
                ))
            }
        </Fragment>
    )

}

/** EposDetails propTypes
 * PropTypes
*/
EposDetails.propTypes = {
    /** Agent ID */
    agentId: PropTypes.string,
    /** Redux state property, epos agents list */
    agents: PropTypes.arrayOf(eposAgentType)
};

const mapStateToProps = (state) => {
    return {
        agents: state.epos.agents
    };
};

export default connect(mapStateToProps, null)(EposDetails);