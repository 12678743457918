import React, { useEffect } from 'react';
import PropTypes from "prop-types";

import { connect } from "react-redux";

import BankSelect from "./bankSelect";
import BankForm from "./bankForm";

import { getBanks, setSelectedBank } from "store/actions/banks.actions";

import { isPreviewMode } from 'utils/common';

/** Bank Transfer Component */
const BankTransferComponent = ({
    getBanks,
    setSelectedBank,
    selectedBank
}) => {

    /** Load Banks */
    useEffect(() => {
        getBanks()
    }, [])

    /** Set default bank on preview mode */
    useEffect(() => {
        if (isPreviewMode()) {
            const urlParams = new URLSearchParams(window.location.search);
            const bankId = urlParams.get("bankId");
            if (bankId) {
                setSelectedBank({id: bankId})
            }
        }
    }, []);

    return selectedBank? <BankForm /> : <BankSelect />
}

/** BankTransferComponent propTypes
 * PropTypes
*/
BankTransferComponent.propTypes = {
    /** Redux action to get banks */
    getBanks: PropTypes.func,
    /** Redux state property selected bank */
    selectedBank: PropTypes.shape({
        id: PropTypes.string,
        amount: PropTypes.number
    }),
    /** Redux action to set selected bank */
    setSelectedBank: PropTypes.func
};

const mapStateToProps = (state) => {
    return {
        selectedBank: state.banks.selectedBank
    };
};

const mapDispatchToProps = (dispatch) => ({
    getBanks: () => {
        dispatch(getBanks());
    },

    setSelectedBank: bank => {
        dispatch(setSelectedBank(bank));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(BankTransferComponent);
