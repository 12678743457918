import React, { useEffect , Fragment } from 'react';
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';

import { getBankDetails, getBankAgentDetails } from "store/actions/banks.actions";

import { isPreviewModeForAgent, toUpperCaseFirstLetter, getSystemUrls } from 'utils/common';
import { sendPostMessage } from 'utils/postMessage';

import infoType from 'types/info.type';
import bankDetailsType from 'types/bankDetails.type';

const isImgFile = (value) => {
    return value.startsWith("[") && value.endsWith("]")
}

/** Bank Details Component */
const BankDetails = ({
    getBankDetails,
    getBankAgentDetails,
    bankDetails,
    bankId,
    amount,
    info,
    agentId
}) => {

    const { t } = useTranslation();

    /** Details */
    const details = bankDetails && bankDetails.details ? bankDetails.details : {};

    const hasDetails = Object.keys(details).length > 0;

    /** Load Bank details */
    useEffect(() => {
        if(!isPreviewModeForAgent()){
            if(agentId){
                getBankAgentDetails(agentId, bankId)
            } else {
                getBankDetails(bankId, amount)
            }
        } else {
            sendPostMessage({
                action: "getAgentDetails",
                transactionType: info.transactionType
            })
        }
        
    }, [bankId])


    return hasDetails ? (
        <div className='rt--info rt--pl-16 rt--pr-16 rt--pb-16 rt--pt-16 rt--mb-16'>
            <span className='rt--title rt--info-title rt--font-bold rt--font-big'>{t("widget.common.agentInformation")}</span>
            <div className='rt--info-list rt--mt-12'>
                {
                    Object.keys(details).map(detail => (
                        <div className='rt--info-item rt--flex rt--align-center rt--justify-between rt--mb-8' key={detail}>
                            <span className='rt--title rt--font-regular rt--font-normal rt--pr-12'>
                                {toUpperCaseFirstLetter(detail)}:
                            </span>
                            {
                                isImgFile(details[detail])
                                    ? (
                                        <img className='rt--info-item-img' src={`${getSystemUrls().CDN}/${details[detail].slice(1, details[detail].length - 1)}`} />
                                    ) : (
                                        <b className='rt--title rt--font-medium rt--font-normal'>
                                            {details[detail]}
                                        </b>
                                    )
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    ) : <Fragment />
}

/** BankDetails propTypes
 * PropTypes
*/
BankDetails.propTypes = {
    /** Redux action to get bank details */
    getBankDetails: PropTypes.func,
    /** Redux action to get bank agent details */
    getBankAgentDetails: PropTypes.func,
    /** Redux state property, selected bank form */
    bankDetails: bankDetailsType,
    /** Bank ID */
    bankId: PropTypes.string,
    /** Amount */
    amount: PropTypes.number,
    /** Redux state property, current info */
    info: infoType,
    /** Is For pending request */
    agentId: PropTypes.string
};

const mapStateToProps = (state) => {
    return {
        bankDetails: state.banks.details.details,
        info: state.auth.info
    };
};

const mapDispatchToProps = (dispatch) => ({
    getBankDetails: (id, amount)  => {
        dispatch(getBankDetails(id, amount))
    },
    getBankAgentDetails: (agentId, bankId)  => {
        dispatch(getBankAgentDetails(agentId, bankId))
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(BankDetails);