/** Session */

export const SET_SESSION = "SET_SESSION";
export const SET_SESSION_LOADED = "SET_SESSION_LOADED";
export const SET_INFO = "SET_INFO";
export const SET_SESSION_EXPIRED = "SET_SESSION_EXPIRED";

/** Common */

export const SET_TRANSLATIONS_LOADED = "SET_TRANSLATIONS_LOADED";
export const SET_MESSAGE = "SET_MESSAGE";

/** Banks */

export const SET_BANKS_ACTION_BEFORE = "SET_BANKS_ACTION_BEFORE";
export const SET_BANKS_ACTION_FINISH = "SET_BANKS_ACTION_FINISH";
export const SET_BANKS = "SET_BANKS";
export const SET_BANK_FORM_ACTION_BEFORE = "SET_BANK_FORM_ACTION_BEFORE";
export const SET_BANK_FORM_ACTION_FINISH = "SET_BANK_FORM_ACTION_FINISH";
export const SET_BANK_FORM = "SET_BANK_FORM";
export const SET_BANK_TRANSLATIONS = "SET_BANK_TRANSLATIONS";
export const SET_BANK_DETAILS_ACTION_BEFORE = "SET_BANK_DETAILS_ACTION_BEFORE";
export const SET_BANK_DETAILS_ACTION_FINISH = "SET_BANK_DETAILS_ACTION_FINISH";
export const SET_BANK_DETAILS = "SET_BANK_DETAILS";
export const SET_BANK_DETAILS_ERROR = "SET_BANK_DETAILS_ERROR";
export const UPDATE_BANK_DETAILS = "UPDATE_BANK_DETAILS";
export const SET_SELECTED_BANK = "SET_SELECTED_BANK";
export const RESET_BANK = "RESET_BANK";

/** Epos */

export const SET_EPOS_ACTION_BEFORE = "SET_EPOS_ACTION_BEFORE";
export const SET_EPOS_ACTION_FINISH = "SET_EPOS_ACTION_FINISH";
export const SET_EPOS_AGENTS = "SET_EPOS_AGENTS";
export const SET_EPOS_SELECTED_AGENT_ID = "SET_EPOS_SELECTED_AGENT_ID";

/** Transactions */

export const SET_TRANSACTION_ACTION_BEFORE = "SET_TRANSACTION_ACTION_BEFORE";
export const SET_TRANSACTION_ACTION_FINISH = "SET_TRANSACTION_ACTION_FINISH";
export const SET_TRANSACTION_DONE = "SET_TRANSACTION_DONE";
export const SET_TRANSACTION_RETRY = "SET_TRANSACTION_RETRY";
