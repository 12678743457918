import React, { Fragment, useEffect } from 'react';
import PropTypes from "prop-types";

import { connect } from "react-redux";

import Global from "components/global";
import MainComponent from 'components/main';

import { launch } from "store/actions/auth.actions";

/** Main Layout Component */
const Layout = ({
    launch
}) => {
    
    useEffect(() => {
		launch();
	}, []);

    return (    
        <Fragment>
            <Global />
            <div className='rt--container'>
                <MainComponent />
            </div> 
        </Fragment>
    )
}

Layout.propTypes = {
	/** Redux action to launch widget */
	launch: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
	launch: () => {
		dispatch(launch());
	}
});


export default connect(null, mapDispatchToProps)(Layout);