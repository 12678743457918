import { useState, useMemo, useEffect } from 'react';
import moment from "moment";

const useCountDown = (startTime, currentTime) => {
	
	const [timeLeft, setTimeLeft] = useState(null);

	const formattedTime = useMemo(() => {
		if(timeLeft === null){
			return {}
		}
		const duration = moment.duration(timeLeft);


		return {
			days: `${duration.days()}`.padStart(2, '0'),
			hours: `${duration.hours()}`.padStart(2, '0'),
			minutes: `${duration.minutes()}`.padStart(2, '0'),
			seconds: `${duration.seconds()}`.padStart(2, '0')
		}
	}, [timeLeft]);

	useEffect(() => {
		if (startTime) {
			const difference = moment(startTime).diff(moment.unix(currentTime));

			if (difference >= 0) {
				setTimeLeft(difference);
			}
		}
	}, [currentTime, startTime]);

	return formattedTime;
}

export default useCountDown;