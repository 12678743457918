import React from "react"
import { createPortal } from 'react-dom';

import PropTypes from "prop-types";

import Drawer from "components/ui/drawer";

const SelectMobileDropdown = ({
    visible,
    dropdownClassname,
    options,
    value,
    onChange,
    toggleDropdown,
    placeholder
}) => {

    const handleClick = option => {
        if(value !== option.value){
            onChange?.(option.value);
            toggleDropdown()
        }
    }

    return (
        <Drawer 
            visible={visible}
            title={placeholder}
            onClose={toggleDropdown}
            className={dropdownClassname}
        >
            <div className="rt--select-menu">
                {
                    options.map(option => (
                        <div 
                            className={
                                "rt--select-menu-item" +
                                (option.value === value ? " rt--select-menu-item-selected" : "")
                            } 
                            key={option.value}
                            onClick={() => handleClick(option)}
                        >
                            <span>{option.text}</span>
                        </div>
                    ))
                }
            </div>
            
        </Drawer>
    )
}

/** SelectMobileDropdown propTypes
    * PropTypes
*/
SelectMobileDropdown.propTypes = {
	/** Is popup visible */
	visible: PropTypes.bool,
	/** Elements to render  */
	options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        text: PropTypes.string
    })),
	/** Selected value */
	value: PropTypes.string,
	/** Class to rewrite default styles of select dropdown */
	dropdownClassname: PropTypes.string,
    /** Function to change value */
    onChange: PropTypes.func,
    /** Function to toggle dropdown */
    toggleDropdown: PropTypes.func,
    /** Placeholder */
    placeholder: PropTypes.string,
};

export default SelectMobileDropdown;