import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import moment from 'moment'

import useCountDown from "hooks/useCountDown";

import { setSessionExpired } from 'store/actions/auth.actions';

let interval = null;

/** Session Countdown Component */
const SessionCountdown = ({
    expires,
    setSessionExpired
}) => {

    const { t } = useTranslation();

    const [countdownValue, setCountdownValue] = useState(null);
    const [currentTime, setCurrentTime] = useState(moment().unix())

    /** Keep current time */
    useEffect(() => {
        interval = setInterval(() => {
            setCurrentTime(moment().unix());
        }, 1000);

        return () => {
            clearInterval(interval)
        }
    }, []);

    const time = useCountDown(countdownValue, currentTime);

    /** Function to initialize Countdown
		* @function
		* @param { date } date
		* @memberOf SessionCountdown
  */
	const initialize = date => {
		if (moment().diff(date, "seconds") < 0) {
			setCountdownValue(moment.utc(date).local().toDate());
		}
	};

    /** Reinitialize Countdown on date change */
	useEffect(() => {
		initialize(moment.utc(expires).local().toDate())
	}, [expires]);

    useEffect(() => {
        if(time.minutes === "00" && time.seconds === "00"){
            setSessionExpired()
        }
    }, [time])

    if(countdownValue === null) {
		return <Fragment />
	}

    return (
        <div className="rt--main-session rt--flex rt--justify-end rt--align-center">
            <span className='rt--title rt--font-small rt--font-regular rt--pl-4 rt--pr-4'>{t("widget.common.sessionWillExpireIn")}</span>
            <b className='rt--title rt--font-normal rt--font-medium'>
                { `${time.minutes}:${time.seconds}` }
            </b>
        </div>
    )
}

/** SessionCountdown propTypes
    * PropTypes
*/
SessionCountdown.propTypes = {
    /** Redux state property expire date */
    expires: PropTypes.string,
    /** Redux action to set session expired */
    setSessionExpired: PropTypes.func
};

const mapStateToProps = (state) => {
    return {
        expires: state.auth.session.expires   
    };
};

const mapDispatchToProps = (dispatch) => ({
    setSessionExpired: () => {
        dispatch(setSessionExpired())
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(SessionCountdown);