import React, { Fragment } from 'react';

import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';

import BankTransfer from "components/bankTransfer";
import Epos from "components/epos";
import SessionCountDown from 'components/ui/sessionCountDown';
import PendingRequest from 'components/ui/pendingRequest';

import { PAYMENT_TYPE } from "constants/common.constants";

import { isMobile } from 'utils/common';

import infoType from 'types/info.type';

/** Main Page Card Component */
const CardComponent = ({
    info
}) => {

    const { t } = useTranslation()

    return (
        <div className='rt--main-card rt--flex rt--flex-col rt--mt-20'>
            <div className='rt--main-card-header rt--pl-24 rt--pr-24 rt--flex rt--align-center rt--justify-between'>
                <span className='rt--title rt--font-big rt--font-bold'>
                    {
                        info.paymentType === PAYMENT_TYPE.BANK_TRANSFER ? t("widget.common.bankTransfer") :
                            info.paymentType === PAYMENT_TYPE.EPOS ? t("widget.common.epos") : ""
                    }
                </span>
                <SessionCountDown />
            </div>
            <div className={'rt--main-card-content rt--flex rt--flex-col rt--flex-equal' + (!isMobile() ? " rt--pl-24 rt--pr-16 rt--pb-16 rt--pt-16" : "")}>
                {
                    info.transactionRequest ? <PendingRequest /> : (
                        <Fragment>
                            {
                                info.paymentType === PAYMENT_TYPE.BANK_TRANSFER ? <BankTransfer /> :
                                    info.paymentType === PAYMENT_TYPE.EPOS ? <Epos /> : <Fragment />
                            }
                        </Fragment>
                    )
                }

            </div>

        </div>
    )
}

/** CardComponent propTypes
    * PropTypes
*/
CardComponent.propTypes = {
    /** Redux state property, current info */
    info: infoType
};

const mapStateToProps = (state) => {
    return {
        info: state.auth.info,
    };
};

export default connect(mapStateToProps, null)(CardComponent);