import React from 'react';
import PropTypes from 'prop-types';

/** Loader Component */
const Loader = ( { full, style, fixed }) => {
    return (
        <div className={"rt--loader rt--flex rt--flex-row rt--align-center rt--justify-center " + (fixed ? " rt--loader-fixed" : "")} style={style ? style : full ? {height: "100vh"} : null} >
            <div className="rt--loader-inner"></div>
        </div>
    )
}

/** Loader propTypes
    * PropTypes
*/
Loader.propTypes = {
    /** Should loader be centered on screen */
    full: PropTypes.bool,
    /** Css style of loader */
    style: PropTypes.object,
    /** Should loader be full page fixed, with overlay */
    fixed: PropTypes.bool
}

export default Loader;