import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

/** Upload Component */
const Upload = ( { 
    value,
    onChange,
    disabled=false,
    accept,
    title="",
    infoText=""
}) => {

    const [fileURL, setFileURL] = useState(value ? window.URL.createObjectURL(value) : null);
    const [dragging, setDragging] = useState(false);

    useEffect(() => {
        if(value){
            setFileURL(window.URL.createObjectURL(value))
        } else {
            setFileURL(null)
        }
    }, [value])

    const _inputRef = useRef(null);

    const handleClick = () => {
        _inputRef.current && _inputRef.current.click();
    }

    const handleChange = e => {
        const selectedFile = e.target.files[0];
        setFileURL(window.URL.createObjectURL(selectedFile));
        onChange && onChange(selectedFile);
    }

    const handleRemove = () => {
        setFileURL(null);
        onChange && onChange(null);
        if(_inputRef.current){
            _inputRef.current.value = null;
        }
    }

    const handleDragOver = (e) => {
        e.preventDefault();
        if (!disabled) {
            setDragging(true);
        }
    };
    
    const handleDragLeave = () => {
        setDragging(false);
    };
    
    const handleDrop = (e) => {
        e.preventDefault();
        if (!disabled) {
            const selectedFile = e.dataTransfer.files[0];
            setFileURL(window.URL.createObjectURL(selectedFile));
            onChange && onChange(selectedFile);
            setDragging(false);
        }
    };

    return (
        <div 
            className={
                'rt--upload' + 
                (dragging ? " rt--upload-dragging" : "") +
                (disabled ? " rt--upload-disabled" : "")
            }
            onClick={handleClick}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
        >
            <input
                ref={_inputRef} 
                type="file" 
                accept={accept}
                onChange={handleChange}
                disabled={disabled}
            />
            <div className='rt--upload-content'>
                {
                    fileURL ? (
                        <div className='rt--upload-preview'>
                            <img src={fileURL} />
                            <div 
                                className='rt--upload-actions'
                                onClick={e => e.stopPropagation()}
                            >
                                <div
                                    className='rt--upload-actions-button'
                                    onClick={handleRemove}
                                >
                                    <i className='icon-trash' />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='rt--upload-empty'>
                            <div className='rt--upload-empty-content'>
                                <b className='rt--title rt--font-regular rt--font-normal'>{title}</b>
                                <span className='rt--title rt--font-regular rt--font-normal'>
                                    {infoText}
                                </span>
                            </div>
                            <i className='icon-upload' />
                        </div>
                    )
                }
            </div>
        </div>
    )
}

/** Upload propTypes
    * PropTypes
*/
Upload.propTypes = {
    /** Uploaded File */
    value: PropTypes.object,
    /** OnChange Function */
    onChange: PropTypes.func,
    /** Is disabled */
    disabled: PropTypes.bool,
    /** Accept formats */
    accept: PropTypes.arrayOf(PropTypes.string),
    /** Title */
    title: PropTypes.string,
    /** Info Text */
    infoText: PropTypes.string
}

export default Upload;