import React from 'react';
import PropTypes from 'prop-types';

import { connect } from "react-redux";

import Loader from "components/ui/loader";
import Message from "components/ui/message";
import SessionExpired from "components/ui/sessionExpired";
import RequestSuccess from 'components/ui/requestSuccess';
import Card from "./card";

import { isMobile } from 'utils/common';

import infoType from 'types/info.type';
import messageType from 'types/message.type';

/** Main Page Component */
const MainComponent = ({
    info,
    message,
    sessionLoaded,
    sessionExpired,
    transactionData
}) => {

    return (
        <div className={'rt--main rt--flex rt--flex-col rt--align-center' + (!isMobile() ? " rt--justify-center" : "")}>
            {
                sessionExpired ? (
                    <SessionExpired />
                ) : !sessionLoaded ?
                    (
                        <Loader full={true} />
                    ) :
                    transactionData !== null ? (
                        <RequestSuccess />
                    ) : message ? (
                        <Message message={message} />
                    ) : sessionLoaded && info.paymentType ? (
                        <Card />
                    ) : null
            }
        </div>
    )
}

/** MainComponent propTypes
    * PropTypes
*/
MainComponent.propTypes = {
    /** Redux state property, current info */
    info: infoType,
    /** Redux state property, message */
    message: messageType,
    /** Redux state property, is true when session loaded */
    sessionLoaded: PropTypes.bool,
    /** Redux state property, is true when session expired */
    sessionExpired: PropTypes.bool,
    /** Redux state property, Transaction data */
    transactionData: PropTypes.object
};

const mapStateToProps = (state) => {
    return {
        info: state.auth.info,
        message: state.common.message,
        sessionLoaded: state.auth.sessionLoaded,
        sessionExpired: state.auth.sessionExpired,
        transactionData: state.transaction.data
    };
};

export default connect(mapStateToProps, null)(MainComponent);
