import InternalForm from './form';
import FormItem from './item';

import useForm from './hooks/useForm';

const Form = InternalForm;

Form.Item = FormItem;
Form.useForm = useForm;

export default Form;