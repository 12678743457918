import React, { useState } from 'react';

import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import BanksList from "components/bankTransfer/banksList";
import Error from "components/bankTransfer/error";
import EditableAmount from 'components/ui/editableAmount';
import BankTransferWrapper from '../bankTransferWrapper';

import { setSelectedBank } from "store/actions/banks.actions";

import { isPreviewMode } from 'utils/common';

import bankType from 'types/bank.type';
import infoType from 'types/info.type';

const BankSelect = ({
    retry,
    banks,
    error,
    info,
    isSaving,
    setSelectedBank
}) => {
    const { t } = useTranslation();

    const [selectedBankId, setSelectedBankId] = useState(null);
    const [amount, setAmount] = useState(info.amount ? String(info.amount) : "");

    const isRetryAllowed = retry?.isRetryAllowed ?? false;

    const amountToNum = amount === "" ? 0 : Number(amount);

    const handleForm = () => {
        setSelectedBank({
            amount: amountToNum,
            id: selectedBankId
        })
    }

    return (
        <BankTransferWrapper
            footer={(
                <button
                    className="rt--button rt--button-primary"
                    onClick={handleForm}
                    disabled={
                        isPreviewMode() || error || isSaving || (!selectedBankId || amountToNum === 0)
                    }
                    type="submit"
                >
                    <span>{ isRetryAllowed ? t("widget.common.retry") : t("widget.common.next") }</span>
                </button>
            )}
        >
            <div>
                <div className='rt--main-content-bank-list'>
                    <label title={t('widget.common.chooseBank')} className='rt--main-content-bank-list-label'>{t('widget.common.chooseBank')}</label>
                    <div className='rt--main-content-bank-list-items'>
                        <BanksList
                            banks={banks}
                            value={selectedBankId}
                            onChange={setSelectedBankId}
                        />
                    </div>
                </div>
                {
                    selectedBankId && (
                        <EditableAmount 
                            value={amount}
                            onChange={setAmount}
                        />
                    )
                }

                <Error error={error} info={info}/>
            </div>
        </BankTransferWrapper>
        
    );
};

/** BankSelect propTypes
 * PropTypes
*/
BankSelect.propTypes = {
    /** Redux state property, banks list */
    banks: PropTypes.arrayOf(bankType),
    /** Redux state property, error object in case of no agent */
    error: PropTypes.object,
    /** Redux state property, current info */
    info: infoType,
    /** Redux state property, is true when banks are saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is retry state */
    retry: PropTypes.object,
    /** Redux action to set selected bank */
    setSelectedBank: PropTypes.func
};

const mapStateToProps = (state) => {
    return {
        banks: state.banks.banks,
        error: state.banks.error,
        info: state.auth.info,
        retry: state.transaction.retry,
        isSaving: state.transaction.isLoading
    };
};

const mapDispatchToProps = (dispatch) => ({
    setSelectedBank: bank => {
        dispatch(setSelectedBank(bank));
    }
});


export default connect(mapStateToProps, mapDispatchToProps)(BankSelect);
