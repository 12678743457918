import {
    SET_BANKS_ACTION_BEFORE,
    SET_BANKS_ACTION_FINISH,
    SET_BANKS,
    SET_BANK_FORM_ACTION_BEFORE,
    SET_BANK_FORM_ACTION_FINISH,
    SET_BANK_FORM,
    SET_BANK_TRANSLATIONS,
    SET_BANK_DETAILS_ACTION_BEFORE,
    SET_BANK_DETAILS_ACTION_FINISH,
    SET_BANK_DETAILS,
    SET_BANK_DETAILS_ERROR,
    UPDATE_BANK_DETAILS,
    SET_SELECTED_BANK,
    RESET_BANK
} from "store/actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_BANKS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            }
        case SET_BANKS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false
            }
        case SET_BANKS:
            return {
                ...state,
                banks: payload.banks
            }
        case SET_BANK_FORM_ACTION_BEFORE:
            return {
                ...state,
                form: {
                    ...state.form,
                    isLoading: true
                },
            }
        case SET_BANK_FORM_ACTION_FINISH:
            return {
                ...state,
                form: {
                    ...state.form,
                    isLoading: false
                },
            }
        case SET_BANK_FORM:
            return {
                ...state,
                form: {
                    ...state.form,
                    forms: payload.forms
                },
            }
        case SET_BANK_TRANSLATIONS:
            return {
                ...state,
                form: {
                    ...state.form,
                    translations: payload.translations
                },
            }
        case SET_BANK_DETAILS_ACTION_BEFORE:
            return {
                ...state,
                details: {
                    ...state.details,
                    isLoading: true
                },
            }
        case SET_BANK_DETAILS_ACTION_FINISH:
            return {
                ...state,
                details: {
                    ...state.details,
                    isLoading: false
                },
            }
        case SET_BANK_DETAILS:
            return {
                ...state,
                details: {
                    ...state.details,
                    details: payload.details
                },
                error: null
            }
        case SET_BANK_DETAILS_ERROR:
            return {
                ...state,
                details: {
                    ...state.details,
                    details: {}
                },
                form: {
                    ...state.form,
                    form: []
                },
                error: payload.error
            }
        case UPDATE_BANK_DETAILS:
            return {
                ...state,
                details: {
                    ...state.details,
                    details: {
                        ...state.details.details,
                        details: payload.details
                    },
                    error: null
                },
            }
        case SET_SELECTED_BANK:
            return {
                ...state,
                selectedBank: payload.bank
            }
        case RESET_BANK:
            return {
                ...state,
                isLoading: false,
                form: {
                    isLoading: false,
                    forms: [],
                    translations: []
                },
                details: {
                    isLoading: false,
                    details: {}
                },
                error: null,
                selectedBank: null
            }
        default:
            return state
    }
}
