import React, { cloneElement } from "react";

import PropTypes from "prop-types";

import useFormItem from "./hooks/useFormItem";

const FormItem = ({
    children,
    name,
    rules,
    label,
    dependencies,
	valuePropName = "value",
    className = "",
    ...rest
}) => {

    const { value, validationState, _INTERNAL_ } = useFormItem({
		name,
		rules,
		dependencies,
	});

    const { onChange } = children.props;

    const handleFieldChange = event => {
        if(!name) return onChange(event);
        let value;
        if (event?.target instanceof HTMLInputElement){
            value = event.target.value
        } else {
            value = event;
        }
        _INTERNAL_.updateFieldValue({ fieldName: name, value });
        if(typeof onChange === "function") {
			onChange(value);
		}

        if (Array.isArray(rules)) {
			_INTERNAL_.updateFieldValidation({ fieldName: name, value });
		}

        if (Array.isArray(dependencies)) {
			_INTERNAL_.updateFieldDependencies(dependencies);
		}
    }

    return (
        <div 
            className={`rt--form-item ${className} ${(validationState.isVisible ? " rt--form-item-error" : "")}`}
            { ...rest }
        >
            <div className="rt--form-item-content">
                <div className="rt--form-item-label">
                    <label htmlFor={name}>{label}</label>
                </div>
                {cloneElement(children, {
                    [valuePropName]: value,
                    id: name,
                    onChange: handleFieldChange,
                    className: "rt--form-item-control"  + (validationState.isVisible ? " rt--form-item-control-error" : "" ) ,
                })}
            </div>
            {
                validationState.isVisible && (
                    <span className="rt--form-item-message">
                        { validationState.message ?? ""}
                    </span>
                )
            }
            
        </div>
    )
}

/** FormItem propTypes
 * PropTypes
 */
FormItem.propTypes = {
	/** Item to render */
	children: PropTypes.node,
	/** Name of field */
	name: PropTypes.string,
	/** List of Validations (will be passed after each change) */
	rules: PropTypes.array,
	/** Label of field */
	label: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.node
	]),
	/** List of fields name which will be revalidated after current field change */
	dependencies: PropTypes.array,
	/** If we send child elements which have a different prop value name, we must specify that name with that prop */
	valuePropName: PropTypes.string,
	/** Class to rewrite default styles */
	className: PropTypes.string,
};

export default FormItem;