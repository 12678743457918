import React, { Fragment } from 'react';
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { isMobile } from 'utils/common';

import Loader from 'components/ui/loader';
import Alert from 'components/ui/alert';

/** Bank Transfer Component */
const BankTransferWrapperComponent = ({
    children,
    footer,
    isLoading,
    isSaving,
    retry
}) => {

    return (
        <Fragment>
            <div className={
                'rt--main-content rt--align-center rt--justify-center rt--flex-equal' +
                (isLoading ? " rt--flex rt--flex-col" : "") +
                (isMobile() ? " rt--pl-16 rt--pr-16 rt--pb-16 rt--pt-16" : " rt--pr-8")
            }>
                {
                    !isLoading && !isSaving ? (
                        <Fragment>
                            {
                                retry !== null && (
                                    <Alert
                                        type="error"
                                        text={retry?.text}
                                    />
                                )
                            }
                            { children }
                        </Fragment>
                    ) : (
                        <div className='rt--main-content-loader rt--flex rt--align-center rt--justify-center'>
                            <Loader />
                        </div>
                    )
                }
            </div>
            {
                !isLoading && (
                    <div className={
                        'rt--main-footer rt--flex rt--align-center rt--justify-end ' +
                        (isMobile() ? "rt--pl-16 rt--pr-16 rt--pt-8 rt--pb-8" : "rt--mt-20 rt--pr-8 rt--pt-16")
                    }>
                        { footer }
                    </div>
                )
            }
        </Fragment>
    )
}

/** BankTransferComponent propTypes
 * PropTypes
*/
BankTransferWrapperComponent.propTypes = {
    /** Redux state property, is true when banks are loading */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when banks are saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is retry state */
    retry: PropTypes.object,
    /** Children */
    children: PropTypes.node,
    /** Footer */
    footer: PropTypes.node
};

const mapStateToProps = (state) => {
    return {
        isLoading: state.banks.isLoading,
        isSaving: state.transaction.isLoading,
        retry: state.transaction.retry
    };
};

export default connect(mapStateToProps, null)(BankTransferWrapperComponent);