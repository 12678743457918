import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";

import Amount from 'components/ui/amount';
import BankDetails from "components/bankTransfer/details";
import FormId from "components/ui/formId";

import { getEposAgent } from 'store/actions/epos.actions';

import { PAYMENT_TYPE, TRANSACTION_TYPE } from 'constants/common.constants';

import infoType from 'types/info.type';
import eposAgentType from 'types/eposAgent.type';

/** Pending Request Component */
const PendingRequestComponent = ({
    info,
    getEposAgent,
    agents
}) => {

    const { t } = useTranslation();

    const transactionRequest = info?.transactionRequest;

    const amount = transactionRequest.amount;
    const currencyCode = transactionRequest.currencyCode;
    const bankId = transactionRequest.bankId;
    const agentId = transactionRequest.agentId;

    /** Get EPOS Agent */
    useEffect(() => {
        if (info.paymentType === PAYMENT_TYPE.EPOS) {
            getEposAgent(agentId)
        }
    }, [])

    const agent = agents[0]

    return (
        <div className="rt--pending-request rt--flex-equal">
            <div className="rt--pending-request-text rt--flex rt--mb-12">
                <i className='icon-warning' />
                <span
                    className='rt--title rt--pl-4 rt--font-regular rt--font-normal'
                    dangerouslySetInnerHTML={{
                        __html: info.transactionType === TRANSACTION_TYPE.DEPOSIT ?
                            t("widget.common.outstandingDepositRequestMessage").replace("%%amount%%", `<b>${amount} ${currencyCode}</b>`) :
                            t("widget.common.outstandingWithdrawalRequestMessage").replace("%%amount%%", `<b>${amount} ${currencyCode}</b>`)
                    }}
                />
            </div>
            <div className='rt--mb-24'>
                {
                    info.paymentType === PAYMENT_TYPE.BANK_TRANSFER ? (
                        <BankDetails
                            bankId={bankId}
                            agentId={agentId}
                        />
                    ) : agent ? (
                        <div className='rt--info rt--pl-16 rt--pr-16 rt--pb-16 rt--pt-16 rt--mb-16'>
                            <div className='rt--info-list'>
                                <div className='rt--info-item rt--flex rt--align-center rt--justify-between rt--mb-8' >
                                    <span className='rt--title rt--font-regular rt--font-normal rt--pr-12'>{t('widget.common.city')}:</span>
                                    <b className='rt--title rt--font-medium rt--font-normal'>{agent.city}</b>
                                </div>
                                <div className='rt--info-item rt--flex rt--align-center rt--justify-between' >
                                    <span className='rt--title rt--font-regular rt--font-normal rt--pr-12'>{t('widget.common.address')}:</span>
                                    <b className='rt--title rt--font-medium rt--font-normal'>{agent.address}</b>
                                </div>
                            </div>
                        </div>
                    ) : null
                }
            </div>
            <Amount amount={amount} currencyCode={currencyCode}/>
            {
                info.paymentType === PAYMENT_TYPE.EPOS && (
                    <div className='rt--mt-12'>
                        <FormId id={info?.transactionRequest?.verificationCode} text={t("widget.common.code")} />
                    </div>
                )
            }
        </div>
    )
}

/** PendingRequestComponent propTypes
    * PropTypes
*/
PendingRequestComponent.propTypes = {
    /** Redux state property, current info */
    info: infoType,
    /** Redux action to get epos agent */
    getEposAgent: PropTypes.func,
    /** Redux state property, epos agents list */
    agents: PropTypes.arrayOf(eposAgentType)
};

const mapStateToProps = (state) => {
    return {
        info: state.auth.info,
        agents: state.epos.agents
    };
};

const mapDispatchToProps = (dispatch) => ({
    getEposAgent: id => {
        dispatch(getEposAgent(id))
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(PendingRequestComponent);