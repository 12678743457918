import React, { useEffect, Fragment, forwardRef, useImperativeHandle } from 'react';
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';

import XRegExp from 'xregexp';

import Loader from 'components/ui/loader';
import Input from 'components/ui/input';
import Upload from 'components/ui/upload';
import Form from "components/ui//form";

import { getBankForm } from "store/actions/banks.actions";
import { bankTransferDeposit, bankTransferWithdrawal, retryTransaction } from 'store/actions/transaction.actions';

import { isPreviewMode, isPreviewModeForAgent, toLowerCaseFirstLetter } from "utils/common";
import { sendPostMessage } from "utils/postMessage";
import { getLanguage } from 'utils/auth';

import { FORM_CONTROL_RULE, FORM_CONTROL_TYPE } from "constants/form.constants";
import { TRANSACTION_TYPE } from 'constants/common.constants';

import infoType from 'types/info.type';

/** Bank Form Component */
const BankForm = forwardRef(({
    getBankForm,
    bankForm,
    translations,
    isLoading,
    bankId,
    info,
    bankTransferDeposit,
    bankTransferWithdrawal,
    retryTransaction,
    retry
}, ref) => {

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    const { t } = useTranslation();

    const isRetryAllowed = retry?.isRetryAllowed ?? false;
    const lastRequestParams = retry?.lastRequestParams;

    const languageCode = getLanguage();

    /** Load Bank form */
    useEffect(() => {
        if (!isPreviewMode() || isPreviewModeForAgent()) {
            getBankForm(bankId);
        } else {
            sendPostMessage({
                action: "getBankForm",
                transactionType: info.transactionType
            })
        }
    }, [bankId])

    /** Function to get field translation
       * @function
       * @param {string} fieldName
       * @returns {string}
       * @memberOf BankForm
    */
    const getTranslation = fieldName => {
        const translationPrefix = info.transactionType === TRANSACTION_TYPE.DEPOSIT ? "playerdeposit" : "playerwithdrawal";

        const fieldNameTranslations = translations.find(tr => tr.key === `${translationPrefix}_${fieldName}`);
        if (!fieldNameTranslations) return fieldName;

        if (!fieldNameTranslations.translations) return fieldNameTranslations.text;

        return fieldNameTranslations.translations.find(tr => tr.languageCode === languageCode)?.text ?? fieldNameTranslations.text;
    }

    /** Set form values */
    useEffect(() => {
        if (lastRequestParams) {
            const keys = Object.keys(lastRequestParams);
            if (lastRequestParams && keys.length > 0) {
                setTimeout(() => {
                    setFieldsValue(lastRequestParams);
                }, 0)
            }
        }

    }, [retry])

    /** Fires when form submitted
       * @function
       * @memberOf BankForm
    */
    const handleForm = () => {
        validateFields()
            .then((data) => {
                if (!isRetryAllowed) {
                    if (info.transactionType === TRANSACTION_TYPE.DEPOSIT) {
                        bankTransferDeposit(data, info.platformIntegrationVersion)
                    } else {
                        bankTransferWithdrawal(data, info.platformIntegrationVersion)
                    }
                } else {
                    retryTransaction(retry?.id, data);
                }

            }).catch(ex => {
                console.log(ex)
            })

    }

    /** Get rules for control 
       * @function
       * @param {object} - control
       * @returns {array}
       * @memberOf BankForm
   */
    const getRulesForField = control => {
        const rules = [];

        if (control.selectedRule === FORM_CONTROL_RULE.REQUIRED) {
            if (control.controlType === FORM_CONTROL_TYPE.SELECT || control.controlType === FORM_CONTROL_TYPE.UPLOAD) {
                rules.push({ required: true, message: t('widget.common.fieldRequired') })
            } else {
                rules.push({ required: true, whitespace: true, message: t('widget.common.fieldRequired') })
            }
        }

        if (control.regExp) {
            rules.push({ pattern: XRegExp(control.regExp), message: t('widget.common.fieldInvalid') });
        }

        if (control.controlType === FORM_CONTROL_TYPE.UPLOAD) {
            rules.push({
                validator(value) {
                    return new Promise((resolve, reject) => {
                        if (value && value.size > 3 * 1024 * 1024) {
                            reject(t('widget.common.fileSizeExceeded'))
                        } else {
                            resolve()
                        }
                    })
                }
            })
        }

        return rules;
    }

    const controls = bankForm.filter(control => control.name !== "Amount");

    /** Reset fields to inital on bank change */
    useEffect(() => {
        //resetFields()
    }, [bankId])


    useImperativeHandle(ref, () => {
        return {
            handleForm
        }
    })

    return !isLoading ? (
        <Form
            form={formInstance}
            initialValues={
                lastRequestParams && Object.keys(lastRequestParams).length > 0 ? lastRequestParams : undefined
            }
        >
            <div>
                {
                    controls
                        .map((control, index) => (
                            <div key={control.name}>
                                {
                                    control.controlType === FORM_CONTROL_TYPE.INPUT ?
                                        (
                                            <Form.Item
                                                label={`${getTranslation(control.name)} ${control.selectedRule === FORM_CONTROL_RULE.REQUIRED ? '*' : ''}`}
                                                name={toLowerCaseFirstLetter(control.name)}
                                                rules={getRulesForField(control)}
                                                className={
                                                    'rt--general-form-item' +
                                                    (index === controls.length - 1 ? " rt--form-item-without-margin" : "") +
                                                    (isRetryAllowed ? " rt--form-item-disabled" : "")
                                                }
                                                data-placeholder={`${t('widget.common.enter')} ${getTranslation(control.name)}`}
                                            >
                                                <Input
                                                    placeholder={`${t('widget.common.enter')} ${getTranslation(control.name)}`}
                                                    disabled={isRetryAllowed}
                                                />
                                            </Form.Item>
                                        ) : control.controlType === FORM_CONTROL_TYPE.UPLOAD ?
                                            (
                                                <Form.Item
                                                    label={`${getTranslation(control.name)} ${control.selectedRule === FORM_CONTROL_RULE.REQUIRED ? '*' : ''}`}
                                                    name={toLowerCaseFirstLetter(control.name)}
                                                    rules={getRulesForField(control)}
                                                    className={
                                                        (index === controls.length - 1 ? "rt--form-item-without-margin" : "") +
                                                        (isRetryAllowed ? " rt--form-item-disabled" : "")
                                                    }
                                                >
                                                    <Upload 
                                                        disabled={isRetryAllowed}
                                                        accept={["image/png", "image/jpeg", "image/jpg"]}
                                                        title={t("widget.common.uploadPicture")}
                                                        infoText={`JPG, PNG. ${t("widget.common.maxSize")} 3MB`}
                                                    />
                                                </Form.Item>
                                            ) : <Fragment />
                                }
                            </div>
                        ))
                }
            </div>
        </Form>
    ) : <Loader style={{height: "100px"}} />
})

/** BankForm propTypes
 * PropTypes
*/
BankForm.propTypes = {
    /** Redux state property, is true when bank form is loading */
    isLoading: PropTypes.bool,
    /** Redux action to get bank form */
    getBankForm: PropTypes.func,
    /** Redux state property, selected bank form */
    bankForm: PropTypes.array,
    /** Redux state property, selected bank form translations */
    translations: PropTypes.array,
    /** Bank ID */
    bankId: PropTypes.string,
    /** Redux state property, current info */
    info: infoType,
    /** Redux action to bank transfer deposit */
    bankTransferDeposit: PropTypes.func,
    /** Redux action to bank transfer withdrawal */
    bankTransferWithdrawal: PropTypes.func,
    /** Redux action to retry transaction */
    retryTransaction: PropTypes.func,
    /** Redux state property, is retry state */
    retry: PropTypes.object
};

const mapStateToProps = (state) => {
    return {
        bankForm: state.banks.form.forms,
        translations: state.banks.form.translations,
        isLoading: state.banks.form.isLoading,
        info: state.auth.info,
        retry: state.transaction.retry
    };
};

const mapDispatchToProps = (dispatch) => ({
    getBankForm: id => {
        dispatch(getBankForm(id))
    },
    bankTransferDeposit: (data, platformVersion) => {
        dispatch(bankTransferDeposit(data, platformVersion))
    },
    bankTransferWithdrawal: (data, platformVersion) => {
        dispatch(bankTransferWithdrawal(data, platformVersion))
    },
    retryTransaction: (id, data) => {
        dispatch(retryTransaction(id, data))
    }
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(BankForm);