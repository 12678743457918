import React from 'react';
import PropTypes from 'prop-types';

import { getSystemUrls } from 'utils/common';

const BankItem = ({
    bank,
    isSelected,
    onSelect
}) => {
    const handleClick = () => {
        onSelect?.(bank.id);
    }

    return (
        <button 
            className={"rt--main-content-bank-list-items-item" + (isSelected ? ' rt--main-content-bank-list-items-item__selected' : '')} 
            onClick={handleClick}
            title={bank.name}
        >
            <img alt={bank.name} src={getSystemUrls().CDN + '/' + bank.logoPath} />
        </button>
    );
};

BankItem.propTypes = {
    bank: PropTypes.object,
    isSelected: PropTypes.bool,
    onSelect: PropTypes.func,
};

export default BankItem;
