import { applyMiddleware, compose, legacy_createStore } from 'redux';
import thunk from 'redux-thunk';

import rootReducer from 'store/reducers/rootReducer';
import initialState from 'store/initialState';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = preloadedState => {
    return legacy_createStore(
        rootReducer(),
        preloadedState,
        compose(
            applyMiddleware(
                thunk
            ),
            composeEnhancers()
        ),
  );
}

export default configureStore(initialState);