import MessageUtils from "utils/message";

import {
    SET_TRANSLATIONS_LOADED,
    SET_MESSAGE
} from "store/actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_TRANSLATIONS_LOADED:
            return {
                ...state,
                translationsLoaded: true
            }
        case SET_MESSAGE:
            return {
                ...state,
                message: payload.message !== null ? {
                    type: payload.message.type,
                    title: payload.message.title,
                    retry: payload.message.retry,
                    text: MessageUtils.getErrorText(payload.message.message, payload.message.status, payload.message.resource, payload.message.fullMessage)
                } : null
            }
        default:
            return state
    }
}