import PropTypes from 'prop-types';

import { SOCIAL_MEDIA_TYPE } from "constants/common.constants"

export default PropTypes.shape({
    id: PropTypes.string,
    userName: PropTypes.string,
    workingSchedule: PropTypes.shape({
        weekDays: PropTypes.object
    }),
    contacts: PropTypes.arrayOf(PropTypes.shape({
        socialMediaType: PropTypes.oneOf(Object.values(SOCIAL_MEDIA_TYPE)),
        linkInfo: PropTypes.string
    }))
})