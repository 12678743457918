export const PAYMENT_TYPE = {
    BANK_TRANSFER: 1 << 1,
    EPOS: 1 << 2,
}

export const TRANSACTION_TYPE = {
    DEPOSIT: 1,
    WITHDRAW: 2
}

export const SOCIAL_MEDIA_TYPE = {
    TELEGRAM: 1,
    WHATSAPP: 2
}

