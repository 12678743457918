/* Send Post message */
export const sendPostMessage = ( data = {}) => {
	if (window.parent === window) {
		return;
	}
    try {
        window.parent.postMessage(data, '*');
    } catch (ex) {
        console.log(ex)
    }
}