import React, { Fragment, useState } from 'react';
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';

import Empty from 'components/ui/empty';
import Loader from 'components/ui/loader';
import Amount from 'components/ui/amount';
import Select from 'components/ui/select';
import Alert from 'components/ui/alert';
import EditableAmount from 'components/ui/editableAmount';
import EposDetails from './details';

import { getEposAgents, setEposSelectedAgentId } from "store/actions/epos.actions";
import { eposWithdrawal, retryTransaction, cancelRequest } from "store/actions/transaction.actions";

import { isMobile, isPreviewMode } from 'utils/common';

import eposAgentType from 'types/eposAgent.type';
import infoType from 'types/info.type';

/** Epos Component */
const EposComponent = ({
    getEposAgents,
    agents,
    isLoading,
    isSaving,
    eposWithdrawal,
    selectedAgentId,
    setEposSelectedAgentId,
    info,
    retry,
    retryTransaction,
    cancelRequest
}) => {

    const { t } = useTranslation();

    const isRetryAllowed = retry?.isRetryAllowed ?? false;

    const [amount, setAmount] = useState(info.amount ? String(info.amount) : "");

    const [isAmountSelected, setIsAmountSelected ] = useState(false);

    const amountToNum = amount === "" ? 0 : Number(amount);

    const handleForm = () => {
        if (!isRetryAllowed) {
            eposWithdrawal(selectedAgentId, info.platformIntegrationVersion)
        } else {
            retryTransaction(retry?.id, {});
        }
    }

    return (
        <Fragment>
            <div className={
                'rt--main-content rt--align-center rt--justify-center rt--flex-equal' +
                (isLoading ? " rt--flex rt--flex-col" : "") +
                (isMobile() ? " rt--pl-16 rt--pr-16 rt--pb-16 rt--pt-16" : " rt--pr-8")
            }>
                {
                    !isLoading && !isSaving ? (
                        <Fragment>
                            {
                                retry !== null && (
                                    <Alert
                                        type="error"
                                        text={retry?.text}
                                    />
                                )
                            }
                            {
                                isAmountSelected ? (
                                    <Fragment>
                                        <Amount amount={amountToNum} currencyCode={info?.currencyCode}/>
                                        <div className='rt--form-item'>
                                            <div className="rt--form-item-content">
                                                <div className="rt--form-item-label">
                                                    <label>{t('widget.common.address')}</label>
                                                </div>
                                                <Select
                                                    options={
                                                        agents.map(agent => ({
                                                            value: agent.id,
                                                            text: `${agent.address} (${agent.city})`,
                                                        }))
                                                    }
                                                    placeholder={`${t('widget.common.select')} ${t('widget.common.address')}`}
                                                    onChange={agentId => setEposSelectedAgentId(agentId)}
                                                    value={selectedAgentId}
                                                    disabled={isRetryAllowed}
                                                />
                                            </div>
                                        </div>
                                    </Fragment>
                                ) : (
                                    <EditableAmount 
                                        value={amount}
                                        onChange={setAmount}
                                    />
                                )
                            }

                            {
                                selectedAgentId ? (
                                    <EposDetails
                                        agentId={selectedAgentId}
                                    />
                                ) : (
                                    <Empty
                                        message={t('widget.common.noDataYet')}
                                        description={t('widget.common.pleaseChooseAddress')}
                                    />
                                )
                            }
                        </Fragment>
                    ) : (
                        <div className='rt--main-content-loader rt--flex rt--align-center rt--justify-center'>
                            <Loader />
                        </div>
                    )
                }
            </div >
            {
                !isLoading && (
                    <div className={
                        'rt--main-footer rt--flex rt--align-center rt--justify-end ' +
                        (isMobile() ? "rt--pl-16 rt--pr-16 rt--pt-8 rt--pb-8" : "rt--mt-20 rt--pr-8 rt--pt-16")
                    }>
                        {
                            isAmountSelected ? (
                                <Fragment>
                                    <button
                                        className="rt--button rt--button-secondary rt--mr-16 rt--ml-16"
                                        onClick={() => {
                                            setIsAmountSelected(false);
                                            setEposSelectedAgentId(null);
                                            cancelRequest()
                                        }}
                                        type="submit"
                                    >
                                        <span>{ t("widget.common.back") }</span>
                                    </button>
                                    <button
                                        className="rt--button rt--button-primary"
                                        onClick={handleForm}
                                        disabled={isPreviewMode() || !selectedAgentId }
                                        type="submit"
                                    >
                                        <span>
                                            {
                                                !isRetryAllowed ? t("widget.common.request") : t("widget.common.retry")
                                            }
                                        </span>
                                    </button>
                                </Fragment>
                            ) : (
                                <button
                                    className="rt--button rt--button-primary"
                                    onClick={ () => {
                                        setIsAmountSelected(true);
                                        getEposAgents(amount)
                                    }}
                                    disabled={ !amount }
                                    type="submit"
                                >
                                    <span>{ t("widget.common.next") }</span>
                                </button>
                            )
                        }
                        
                    </div>
                )
            }

        </Fragment>
    )
}

/** EposComponent propTypes
 * PropTypes
*/
EposComponent.propTypes = {
    /** Redux state property, is true when addresses are loading */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when addresses are saving */
    isSaving: PropTypes.bool,
    /** Redux action to get epos agents */
    getEposAgents: PropTypes.func,
    /** Redux state property, epos agents list */
    agents: PropTypes.arrayOf(eposAgentType),
    /** Redux action for epos withdrawal */
    eposWithdrawal: PropTypes.func,
    /** Redux action to set epos selected agentId */
    setEposSelectedAgentId: PropTypes.func,
    /** Redux state property, selected agent id */
    selectedAgentId: PropTypes.string,
	/** Redux state property, current info */
    info: infoType,
    /** Redux state property, is retry state */
    retry: PropTypes.object,
    /** Redux action to retry transaction */
    retryTransaction: PropTypes.func,
    /** Redux action to cancel request */
    cancelRequest: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        isLoading: state.epos.isLoading,
        isSaving: state.transaction.isLoading,
        agents: state.epos.agents,
        selectedAgentId: state.epos.selectedAgentId,
		info: state.auth.info,
        retry: state.transaction.retry
    };
};

const mapDispatchToProps = (dispatch) => ({
    getEposAgents: amount => {
        dispatch(getEposAgents(amount))
    },

    eposWithdrawal: (id, platformVersion) => {
        dispatch(eposWithdrawal(id, platformVersion))
    },

    setEposSelectedAgentId: id => {
        dispatch(setEposSelectedAgentId(id))
    },

    retryTransaction: (id, data) => {
        dispatch(retryTransaction(id, data))
    },
    
    cancelRequest: () => {
        dispatch(cancelRequest());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(EposComponent);
