import React, { useEffect } from 'react';

import { Provider } from 'react-redux';

import LanguageUtils from 'utils/languages';

import 'utils/axios';

import { isRTL } from 'utils/common';

import 'translations/config';

import store from 'store/configureStore';

import Layout from 'layouts/main.layout';

import { isIOS, IOSversion } from 'utils/common';

store.subscribe(() => { });

const currentLn = LanguageUtils.getSelectedLanguage();

const App = () => {

	const versionInfo = IOSversion();

	useEffect(() => {
		if (isIOS()) {
			if (versionInfo.version < 16) {
				document.getElementsByClassName("App")[0].classList.add("app-ios-low");
			} else {
				document.getElementsByClassName("App")[0].classList.add("app-ios");
			}
		}

	}, [])

	useEffect(() => {
        if (currentLn) {
            if (isRTL(currentLn)) {
                document.body.classList.add('rt--rtl');
            }
        }
    }, [currentLn])

	return (
		<Provider store={store}>
			<div className="App">
				<Layout />
			</div>
		</Provider>
	)
}



export default App;
