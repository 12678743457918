import React from 'react';
import PropTypes from "prop-types";

import { connect } from "react-redux";

/** Alert Component */
const Alert = ({
    text,
    type
}) => {

    return (
        <div>
            <div className="rt--alert rt--pl-16 rt--pr-16 rt--pb-16 rt--mb-16 rt--pt-16 rt--flex rt--align-center rt--justify-between" data-type={type}>
                <span className='rt--title rt--font-normal rt--font-regular'>{text}</span>
            </div>
        </div>
        
    )
}

/** Alert propTypes
    * PropTypes
*/

Alert.propTypes = {
    /** Alert text */
    text: PropTypes.string,
    /** Alert message type */
    type: PropTypes.oneOf(["error", "success"])
};

const mapStateToProps = (state) => {
    return {
        info: state.auth.info
    };
}

export default connect(mapStateToProps, null)(Alert);