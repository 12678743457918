import React, { useRef, useState, useEffect } from "react";
import { createPortal } from 'react-dom';

import PropTypes from "prop-types";

import useOutsideClick from "hooks/useOutsideClick";
import useEvent from "hooks/useEvent";

const calculateDropdownPosition = element => {
    if(!element) return;
    const positions = element.getBoundingClientRect();
    return {
        left: positions.x,
        top: positions.y + positions.height + 4,
        width: positions.width
    }
}

const SelectDesktopDropdown = ({
    visible,
    dropdownClassname,
    options,
    value,
    onChange,
    elementRef,
    toggleDropdown
}) => {
    const _popupRef = useRef(null);

    const [hoveredItemValue, setHoveredItemValue] = useState(null);

    const handleKeydown = e  => {
        if (e.key === "Enter") {
            if(visible){
                onChange(hoveredItemValue)
            } else {
                toggleDropdown();
            }
        } else if(e.key === "ArrowDown"){
            const selectedItemIndex = options.findIndex(option => option.value === hoveredItemValue);
            let index;
            if(selectedItemIndex === -1){
                index = 0
            } else if(selectedItemIndex >= options.length - 1){
                index = 0;
            } else {
                index = selectedItemIndex + 1;
            }
            setHoveredItemValue(options[index]?.value)
        } else if(e.key === "ArrowUp"){
            const selectedItemIndex = options.findIndex(option => option.value === hoveredItemValue);
            let index;
            if(selectedItemIndex === -1){
                index = 0
            } else if(selectedItemIndex === 0){
                index = options.length - 1;
            } else {
                index = selectedItemIndex - 1;
            }
            setHoveredItemValue(options[index]?.value)
        }
    }

    const setDropdownPosition = () => {
        const positions = calculateDropdownPosition(elementRef.current);
        if(_popupRef.current){
            _popupRef.current.style["left"] = positions.left +'px';
            _popupRef.current.style["top"] = positions.top +'px';
            _popupRef.current.style["minWidth"] = positions.width +'px';
            _popupRef.current.style["maxWidth"] = positions.width +'px';
        }
    }

    useEffect(() => {
        if(visible){
            setDropdownPosition()
        }
    }, [visible])

    /** Calculate dropdown position */
    useEffect(() => {
        setDropdownPosition();
        window.addEventListener("resize", setDropdownPosition);

        return () => {
            window.removeEventListener("resize", setDropdownPosition);
        }
    }, [])

    const handler = useEvent(handleKeydown)

    /** Calculate dropdown position */
    useEffect(() => {
        if(!elementRef.current){
           return;
        }

        elementRef.current.addEventListener("keydown", handler);
        return () => {
            elementRef.current?.removeEventListener("keydown", handler);
        }
        
    }, [handler])

    /** Hovered item */
    useEffect(() => {
        if(options.length > 0){
            setHoveredItemValue(options[0].value)
        }
    }, [options])

    /** Close dropdown on outside click */
    useOutsideClick(elementRef, _popupRef, () => {
		if(visible) {
			toggleDropdown();
		}
	});

    return createPortal(
        (
            <div 
                className={
                    "rt--select-dropdown" +
                    (visible ? " rt--select-dropdown-show " : " rt--select-dropdown-hide ") +
                    dropdownClassname
                }
                ref={_popupRef}
            >
                <div className="rt--select-dropdown-list">
                    {
                        options.map((option, index) => (
                            <div
                                tabIndex={index + 1}
                                className={
                                    "rt--select-dropdown-list-item" +
                                    (option.value === value ? " rt--select-dropdown-list-item-active" : "") +
                                    (option.value === hoveredItemValue ? " rt--select-dropdown-list-item-hovered" : "")
                                }
                                onClick={e => {
                                    e.stopPropagation();
                                    onChange(option.value);
                                }}
                                onMouseOver={() => hoveredItemValue !== option.value && setHoveredItemValue(option.value)}
                                onMouseMove={() => hoveredItemValue !== option.value && setHoveredItemValue(option.value)}
                                key={option.value}
                            >
                                {option.text}
                            </div>
                        ))
                    }
                </div>
            </div> 
        ),
        document.getElementsByClassName("rt--container")[0]
    )
}

/** SelectDesktopDropdown propTypes
 * PropTypes
 */
SelectDesktopDropdown.propTypes = {
	/** Is popup visible */
	visible: PropTypes.bool,
	/** Elements to render  */
	options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        text: PropTypes.string
    })),
	/** Selected value */
	value: PropTypes.string,
	/** Class to rewrite default styles of select dropdown */
	dropdownClassname: PropTypes.string,
    /** Function to change value */
    onChange: PropTypes.func,
    /** Element referance */
    elementRef: PropTypes.object,
    /** Function to toggle dropdown */
    toggleDropdown: PropTypes.func,
};

export default SelectDesktopDropdown;