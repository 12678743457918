import PropTypes from 'prop-types';

import { PAYMENT_TYPE, TRANSACTION_TYPE } from 'constants/common.constants';

export default PropTypes.shape({
    amount: PropTypes.number,
    paymentType: PropTypes.oneOf(Object.values(PAYMENT_TYPE)),
    transactionType: PropTypes.oneOf(Object.values(TRANSACTION_TYPE)),
    currencyCode: PropTypes.string,
    translationPath: PropTypes.string,
    transactionRequest: PropTypes.object
})