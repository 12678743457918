import React, { Fragment, useRef } from 'react';

import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import Amount from "components/ui/amount";
import BankDetails from "components/bankTransfer/details";
import BankFormComponent from "components/bankTransfer/form";
import BankItem from "components/bankTransfer/banksList/bankItem";
import Error from "components/bankTransfer/error";
import BankTransferWrapper from '../bankTransferWrapper';

import { cancelRequest } from "store/actions/transaction.actions";

import { isPreviewMode } from "utils/common";

import bankType from 'types/bank.type';
import infoType from 'types/info.type';

const BankForm = ({
    retry,
    error,
    info,
    selectedBank,
    banks,
    cancelRequest,
    isSaving
}) => {
    const { t } = useTranslation();

    const _formRef = useRef(null);

    const bank = banks?.find(bank => bank.id === selectedBank?.id);

    const isRetryAllowed = retry?.isRetryAllowed ?? false;

    return (
        <BankTransferWrapper
            footer={(
                <Fragment>
                    {
                        !isPreviewMode() ? (
                            <button
                                className="rt--button rt--button-secondary rt--mr-16 rt--ml-16"
                                onClick={cancelRequest}
                                type="submit"
                            >
                                <span>{ t("widget.common.back") }</span>
                            </button>
                        ) : null
                    }
                        <button
                            className="rt--button rt--button-primary"
                            onClick={() => {
                               _formRef?.current?.handleForm()
                            }}
                            disabled={ isPreviewMode() || error || isSaving }
                            type="submit"
                        >
                            <span>
                            {
                                isRetryAllowed ? t("widget.common.retry") : t("widget.common.request")
                            }
                            </span>
                        </button>
                </Fragment>
            )}
        >
            {
                bank && (
                    <div>
                        <Amount amount={selectedBank?.amount} currencyCode={info?.currencyCode}/>
                        <div className='rt--main-content-bank-list'>
                            <label title={t('widget.common.selectedBank')}
                                   className='rt--main-content-bank-list-label'>{t('widget.common.selectedBank')}</label>
                            <div className='rt--main-content-bank-list-items'>
                                <BankItem
                                    isSelected
                                    bank={bank}
                                />
                            </div>
                        </div>
                        <Error error={error} info={info}/>
                        <BankDetails
                            bankId={bank.id}
                            amount={selectedBank?.amount}
                        />
                        <BankFormComponent
                            ref={_formRef}
                            bankId={bank.id}
                        />
                    </div>
                )
            }
        </BankTransferWrapper>
    );
};

BankForm.propTypes = {
    /** Redux state property, error object in case of no agent */
    error: PropTypes.object,
    /** Redux state property, current info */
    info: infoType,
    /** Redux state property, is retry state */
    retry: PropTypes.object,
    /** Redux state property selected bank */
    selectedBank: PropTypes.shape({
        id: PropTypes.string,
        amount: PropTypes.number
    }),
    /** Redux state property, banks list */
    banks: PropTypes.arrayOf(bankType),
    /** Redux action to cancel request */
    cancelRequest: PropTypes.func,
    /** Redux state property, is true when banks are saving */
    isSaving: PropTypes.bool,
}

const mapStateToProps = (state) => {
    return {
        error: state.banks.error,
        info: state.auth.info,
        selectedBank: state.banks.selectedBank,
        retry: state.transaction.retry,
        banks: state.banks.banks,
        isSaving: state.transaction.isLoading
    };
};

const mapDispatchToProps = (dispatch) => ({
    cancelRequest: () => {
        dispatch(cancelRequest());
    }
});


export default connect(mapStateToProps, mapDispatchToProps)(BankForm);
