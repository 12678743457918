import React, { useState, useRef } from "react";

import PropTypes from "prop-types";

import { isMobile } from 'utils/common';
import SelectDesktopDropdown from "./desktopDropdown";
import SelectMobileDropdown from "./mobileDropdown";

/** Select Component */
const Select = ({
	options,
	defaultValue = null,
	placeholder,
	onChange,
	value,
	dropdownClassname = '',
    className ="",
    disabled=false
}) => {

    const _elementRef = useRef(null);

    const isControlledFromOutside = value !== undefined;

    const [selectedItemValue, setSelectedItemValue] = useState(isControlledFromOutside ? defaultValue : undefined);

    const [isDropdownVisible, setIsDropdownVisible] = useState(null);

    const currentSelectedValue = isControlledFromOutside ? value : selectedItemValue;

    const hideDropdown = () => {
		setIsDropdownVisible(false);
	};

    const showdropDown = () => {
        if(disabled){
            return;
        }
		setIsDropdownVisible(true);
	};

    const toggleDropdown = (e) => {
		e?.stopPropagation();

		if (isDropdownVisible) {
			hideDropdown();
		} else {
            showdropDown();
        }
	};

    const handleChange = value => {
        if(currentSelectedValue === value){
            hideDropdown();
            return;
        }

        if (typeof onChange === "function") {
			onChange(value);
		}

        hideDropdown();

        if(!isControlledFromOutside){
            setSelectedItemValue(value)
        }
    }

    const getSelectedItem = () => {
        return options.find(option => option.value === currentSelectedValue)
    }

    return (
        <div 
            className={`rt--select ${className}`}
        >
            <div 
                className={
                    "rt--select-selector" + 
                    (isDropdownVisible ? " rt--select-selector-active" : "") +
                    (disabled ? " rt--select-selector-disabled" : "")
                }
                onClick={toggleDropdown}
                tabIndex="0"
                ref={_elementRef}
            >
                {
                    currentSelectedValue ? (
                        <span>{getSelectedItem()?.text ?? ""}</span>
                    ) : (
                        <div className="rt--select-selector-placeholder">
                            { placeholder }
                        </div>
                    )
                }
                <div
					className={`rt--select-selector-icon ${isDropdownVisible ? "rt--select-selector-icon-active" : ""}`}
				>
					<i className="icon-down"></i>
				</div>
            </div>
            {
                isDropdownVisible !== null && (
                    !isMobile() ? (
                        <SelectDesktopDropdown 
                            visible={isDropdownVisible}
                            dropdownClassname={dropdownClassname}
                            options={options}
                            value={currentSelectedValue}
                            onChange={handleChange}
                            elementRef={_elementRef}
                            toggleDropdown={toggleDropdown}
                        />
                    ) : (
                        <SelectMobileDropdown
                            visible={isDropdownVisible}
                            dropdownClassname={dropdownClassname}
                            options={options}
                            value={currentSelectedValue}
                            onChange={handleChange} 
                            toggleDropdown={toggleDropdown}
                            placeholder={placeholder}
                        />
                    )
                )
            } 
        </div>
    )
}

/** Select propTypes
 * PropTypes
 */
Select.propTypes = {
	/** Function which will fire on change of Select */
	onChange: PropTypes.func,
	/** Class to rewrite default styles of select */
	className: PropTypes.string,
	/** Default selected item's value */
	defaultValue: PropTypes.string,
	/** Elements to render  */
	options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        text: PropTypes.string
    })),
	/** Placeholder of selector */
	placeholder: PropTypes.string,
	/** Selected value controlled from outside */
	value: PropTypes.string,
	/** Class to rewrite default styles of select dropdown */
	dropdownClassname: PropTypes.string,
    /** Is select disabled */
    disabled: PropTypes.bool
};

export default Select;