import React, { Fragment, useEffect } from 'react';
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { setBankForm, setBankTranslations, updateBankDetails } from 'store/actions/banks.actions';

import { isMobile, isPreviewMode } from "utils/common";

import infoType from 'types/info.type';

/** Global Component */
const GlobalComponent = ({
	setBankForm,
	setBankTranslations,
	updateBankDetails,
	info
}) => {

	/** Set mobile attribute */
    useEffect(() => {
		window.scrollTo(0, 0);
		if (isMobile()) {
			document.documentElement.setAttribute("data-ismobile", 1)
		} else {
			document.documentElement.setAttribute("data-ismobile", 0)
		}
	}, [])

	const appHeight = () => {
		const doc = document.documentElement
		doc.style.setProperty('--app-height', window.innerHeight + "px")
	}

	/** Set app height */
	useEffect(() => {
		appHeight();
		window.addEventListener("resize", appHeight)
	}, [])

	/** Initialize post message events */
	useEffect(() => {
		if(isPreviewMode() && info.transactionType){
			window.addEventListener("message", e => {
				const d = e.data;
				if (d) {
					const action = d.action;
					const transactionType = d.transactionType;
					if(transactionType === info.transactionType){
						switch(action){
							case "bankFields":
								setBankForm(d.form)
								break;
							case "bankFieldsTranslations":
								setBankTranslations(d.translations)
								break;
							case "agentDetails":
								updateBankDetails(d.details)
								break;
							default:
								break;
						}
					}
				}
			})
		}
		
	}, [info.transactionType])

    return (
        <Fragment />
    )
}

/** GlobalComponent propTypes
 * PropTypes
*/
GlobalComponent.propTypes = {
    /** Redux action to set bank form */
    setBankForm: PropTypes.func,
	/** Redux action to update bank details */
    updateBankDetails: PropTypes.func,
	/** Redux state property, session info */
    info: infoType
};

const mapStateToProps = (state) => {
    return {
        info: state.auth.info
    };
};

const mapDispatchToProps = (dispatch) => ({
    setBankForm: form => {
        dispatch(setBankForm(form));
    },

	setBankTranslations: translations => {
        dispatch(setBankTranslations(translations));
    },

	updateBankDetails: details => {
        dispatch(updateBankDetails(details));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalComponent);