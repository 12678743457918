import React from 'react';

import { useTranslation } from 'react-i18next';

/** Session Expired Component */
const SessionExpired = () => {

    const { t } = useTranslation();

    return (
        <div className="rt--message rt--mt-20" data-type="error">
            <div className="rt--message-header rt--flex rt--align-center rt--justify-center">
                <span className='rt--title rt--font-bold rt--font-big'>
                    {t("widget.common.sessionExpired")}
                </span>
            </div>
            <div className="rt--message-content  rt--flex rt--align-center rt--justify-center rt--pb-34 rt--pt-34 rt--pl-24 rt--pr-24">
                <span className='rt--title rt--font-normal rt--font-regular'>{t("widget.common.sessionExpiredMessage")}</span>
            </div>
        </div>
    )
}

export default SessionExpired;