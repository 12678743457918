import React from 'react';
import PropTypes from 'prop-types';

import { copyToClipboard } from "utils/common";

/** Form Id Component */
const FormId = ({
    id,
    text
}) => {
    return (
        <div className='rt--form-id rt--flex rt--justify-start rt--align-center rt--pl-12 rt--pr-12 rt--pt-4 rt--pb-4'>
            {
                text && (
                    <b className='rt--title rt--font-bold rt--font-normal'>{text}:</b>
                )
            }

            <span
                className='rt--title rt--font-regular rt--font-normal rt--pl-4 rt--pr-4 rt--flex-equal'
                title={id}
            >{id}</span>
            <i
                className='icon-copy rt--font-bigest'
                onClick={() => copyToClipboard(id)}
            />

        </div>
    )
}

/** FormId propTypes
    * PropTypes
*/
FormId.propTypes = {
    /** The ID */
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** The text */
    text: PropTypes.string
}

export default FormId;