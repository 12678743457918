const VALIDATION_TYPES = {
	REQUIRED: "required",
	PATTERN: "pattern"
};

const VALIDATIONS = {
	[VALIDATION_TYPES.REQUIRED]: function ({ value, message, whitespace }) {
		let fieldValue = value ?? "";

		if(whitespace) {
			fieldValue = fieldValue.trim();
		}

		if (fieldValue === "") {
			return Promise.reject(message);
		}

		return Promise.resolve();
	},

	[VALIDATION_TYPES.PATTERN]: function({ value, message, pattern }) {
        let fieldValue = value ?? "";

		if(fieldValue === "") {
			return Promise.resolve();
		}

		if(pattern.test(fieldValue)) {
			return Promise.resolve();
		}

		return Promise.reject(message);
	}
};

export async function asyncValidator({ rules, value, formInstance }) {
	for (const validation of rules) {
		/**
		 * validation can be object that contains { type: string, message: string }
		 * or it can be function which will receive formInstance and
		 * return object that contains { validator: func }
		 */
		const validationObj = (
			typeof validation === "function" ? validation(formInstance) : validation
		)

		const { required, message, pattern, whitespace, validator } = validationObj;
		try {
			if (typeof validator === "function") {
				await validator(value);
			} else if(typeof required !== "undefined") {
				await VALIDATIONS[VALIDATION_TYPES.REQUIRED]({ value, message, whitespace });
			} else if(typeof pattern !== "undefined") {
				await VALIDATIONS[VALIDATION_TYPES.PATTERN]({ value, message, pattern });
			}
		} catch (validationMessage) {
			// validationMessage return only validator function
			return Promise.reject(validationMessage ? validationMessage : message);
		}
	}

	return Promise.resolve();
}

export const getValidationMessageList = (STORE) => {
	const validationMessageList = [];

	for (const [fieldName, fieldStore] of Object.entries(STORE)) {
		const message = fieldStore.validationData?.message;

		if (!message) {
			continue;
		}

		validationMessageList.push({
			[fieldName]: message,
		});
	}

	return validationMessageList;
};