import React from 'react';

import { useTranslation } from 'react-i18next';

import messageType from 'types/message.type';

/** Message Component */
const Message = ({
    message
}) => {

    const { t } = useTranslation();

    return (
        <div className="rt--message rt--mt-20" data-type={message.type}>
            <div className="rt--message-header rt--flex rt--align-center rt--justify-center">
                <span className='rt--title rt--font-bold rt--font-big'>
                    {message.title ? message.title : t("widget.common.error")}
                </span>
            </div>
            <div className="rt--message-content rt--flex rt--flex-col rt--align-center rt--justify-center rt--pb-34 rt--pt-34 rt--pl-24 rt--pr-24">
                <span className='rt--title rt--text-center rt--font-normal rt--font-regular'>{message.text}</span>
            </div>

        </div>
    )
}

/** Message propTypes
    * PropTypes
*/
Message.propTypes = {
    /** Message */
    message: messageType
};


export default Message;