import axios from "axios";

import ApiUrls from "constants/api.constants";
import Methods from "constants/httpMethods.constants";

import i18n from "translations/config"

import { resetBank } from "store/actions/banks.actions";

import { getLanguage } from "utils/auth";
import { Version } from "utils/version";
import MessageUtils from "utils/message";

import { TRANSACTION_TYPE } from "constants/common.constants";
import { RESPONSE_CODES } from "constants/errorCode.constants";

import {
    SET_TRANSACTION_ACTION_BEFORE,
    SET_TRANSACTION_ACTION_FINISH,
    SET_TRANSACTION_DONE,
    SET_TRANSACTION_RETRY
} from "store/actionTypes";

const setTransactionActionBefore = () => ({
    type: SET_TRANSACTION_ACTION_BEFORE,
});

const setTransactionActionFinished = () => ({
    type: SET_TRANSACTION_ACTION_FINISH,
});

const setTransactionDone = data => ({
    type: SET_TRANSACTION_DONE,
    payload: { data },
});

const setTransactionRetry = data => ({
    type: SET_TRANSACTION_RETRY,
    payload: { data },
});

const onError = (err, dispatch, requestParams, transactionType, translations=[]) => {
    const errorData = err?.response?.data ?? null;

    if(errorData){
        const translationPrefix = transactionType === TRANSACTION_TYPE.DEPOSIT ? "playerdeposit" : "playerwithdrawal";
        const fieldName = errorData?.message;
        let message = "";
        if(fieldName){
            const fieldNameTranslations = translations.find(tr => tr.key === `${translationPrefix}_${fieldName}`);
            if(!fieldNameTranslations){
                message = fieldName;
            } else {
                if(!fieldNameTranslations.translations){
                    message = fieldNameTranslations.text;
                } else {
                    message = fieldNameTranslations.translations.find(tr => tr.languageCode === getLanguage())?.text ?? fieldNameTranslations.text;
                }
            }
        }
        dispatch(setTransactionRetry({
            text: MessageUtils.getErrorText(message, errorData?.resultCode ?? 0, errorData?.resource ?? "", errorData?.fullMessage ?? ""),
            lastRequestParams: requestParams
        }));
    } else {
        dispatch(setTransactionRetry({
            text: MessageUtils.getErrorText("SOMETHING WENT WRONG!", RESPONSE_CODES.SomethingWentWrong, "", ""),
            lastRequestParams: requestParams
        }));
    }

    dispatch(setTransactionActionFinished());
}

const onSuccess = (data, dispatch, requestParams, isRetry = false) => {
    if(!data.isRetryAllowed){
        if(!isRetry){
            dispatch(setTransactionDone(data));
        } else {
            if(data.platformResultCode === RESPONSE_CODES.Success){
                dispatch(setTransactionDone(data));
            } else {
                dispatch(setTransactionRetry({
                    ...data,
                    text: MessageUtils.getErrorText("SOMETHING WENT WRONG!", RESPONSE_CODES.SomethingWentWrong, "", ""),
                    lastRequestParams: requestParams
                }));
            }
        }
    } else {
        dispatch(setTransactionRetry({
            ...data,
            text: i18n.t("widget.common.tryAgainTransaction"),
            lastRequestParams: requestParams
        }));
    }

    dispatch(setTransactionActionFinished());
}

export const bankTransferDeposit = (d, platformVersion) => {
    const formData = new FormData();
    const defaultVersion = new Version("v1.0");
    const platformVersionInstance = new Version(platformVersion);
    if(d){
        Object.keys(d).forEach(k => {
            if(k !== "attachment"){
                formData.append(k, d[k] === undefined ? "" : d[k]);
            } else {
                if(d[k]){
                    formData.append('attachment', d[k]);
                }
            }
        })
    }

    const cancelTokenSource = axios.CancelToken.source();
    return (dispatch, getState) => {

        dispatch(setTransactionActionBefore());

        const requestParams = {
            url: defaultVersion.isLessThan(platformVersionInstance) ? ApiUrls.BANK_TRANSFER_DEPOSIT_FREEZE : ApiUrls.BANK_TRANSFER_DEPOSIT,
            method: Methods.POST,
            headers: {
                "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
            },
            cancelToken: cancelTokenSource.token,
            data: formData,
        }

        return axios(requestParams)
            .then(({ data: { value: data } }) => {
                onSuccess(data, dispatch, d );
            })
            .catch(err => {
                const translations = getState().banks.form.translations;
                onError(err, dispatch, d, TRANSACTION_TYPE.DEPOSIT, translations)
            });
    }
}

export const bankTransferWithdrawal = (d, platformVersion) => {
    const formData = new FormData();

    const defaultVersion = new Version("v1.0");
    const platformVersionInstance = new Version(platformVersion);

    if(d){
        Object.keys(d).forEach(k => {
            if(k !== "attachment"){
                formData.append(k, d[k] === undefined ? "" : d[k]);
            } else {
                if(d[k]){
                    formData.append('attachment', d[k]);
                }
            }
        })
    }

    const cancelTokenSource = axios.CancelToken.source();

    return (dispatch, getState) => {

        dispatch(setTransactionActionBefore());

        const requestParams = {
            url: defaultVersion.isLessThan(platformVersionInstance) ? ApiUrls.BANK_TRANSFER_WITHDRAWAL_FREEZE : ApiUrls.BANK_TRANSFER_WITHDRAWAL,
            method: Methods.POST,
            headers: {
                "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
            },
            cancelToken: cancelTokenSource.token,
            data: formData,
        }

        return axios(requestParams)
            .then(({ data: { value: data } }) => {
                onSuccess(data, dispatch, d );
            })
            .catch(err => {
                const translations = getState().banks.form.translations;
                onError(err, dispatch, d, TRANSACTION_TYPE.WITHDRAW, translations)
            });
    }
}

export const eposWithdrawal = (id, platformVersion) => {
    return dispatch => {
		const defaultVersion = new Version("v1.0");
        const platformVersionInstance = new Version(platformVersion);

        dispatch(setTransactionActionBefore());

        const requestParams = {
            url: defaultVersion.isLessThan(platformVersionInstance) ? ApiUrls.EPOS_WITHDRAWAL_FREEZE : ApiUrls.EPOS_WITHDRAWAL,
            method: Methods.POST,
            data: {
                id,
                languageCode: getLanguage()
            },
        }

        return axios(requestParams)
            .then(({ data: { value: data } }) => {
                onSuccess(data, dispatch);
            })
            .catch(err => {
                onError(err, dispatch, null, TRANSACTION_TYPE.WITHDRAW)
            });
    }
}

export const retryTransaction = (id, d) => {

    return (dispatch, getState) => {

        dispatch(setTransactionActionBefore());

        return axios({
            url: ApiUrls.RETRY_TRANSACTION,
            method: Methods.POST,
            data: {
                id
            }
        })
            .then(({ data: { value: data } }) => {
                onSuccess(data, dispatch, d, true );
            })
            .catch(err => {
                const translations = getState().banks.form.translations;
                onError(err, dispatch, d, TRANSACTION_TYPE.WITHDRAW, translations)
            });
    }
}

export const cancelRequest = () => {
    return dispatch => {
        dispatch(setTransactionRetry(null));
        dispatch(resetBank())
    }
}
