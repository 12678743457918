import { combineReducers } from 'redux';

import AuthReducer from "./auth.reducer";
import CommonReducer from "./common.reducer";
import BanksReducer from './banks.reducer';
import EposReducer from './epos.reducer';
import TransactionReducer from './transaction.reducer';

export default () => combineReducers({
    auth: AuthReducer,
    common: CommonReducer,
    banks: BanksReducer,
    epos: EposReducer,
    transaction: TransactionReducer
});
