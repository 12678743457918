import axios from "axios";

import ApiUrls from "constants/api.constants";
import Methods from "constants/httpMethods.constants";
import { RESPONSE_CODES } from "constants/errorCode.constants";

import { getLanguage } from "utils/auth";
import { isPreviewMode, isPreviewModeForAgent } from "utils/common"
import LanguageUtils from 'utils/languages';

import {
    SET_BANKS_ACTION_BEFORE,
    SET_BANKS_ACTION_FINISH,
    SET_BANKS,
    SET_BANK_FORM_ACTION_BEFORE,
    SET_BANK_FORM_ACTION_FINISH,
    SET_BANK_FORM,
    SET_BANK_TRANSLATIONS,
    SET_BANK_DETAILS_ACTION_BEFORE,
    SET_BANK_DETAILS_ACTION_FINISH,
    SET_BANK_DETAILS,
    SET_BANK_DETAILS_ERROR,
    UPDATE_BANK_DETAILS,
    SET_SELECTED_BANK,
    RESET_BANK
} from "store/actionTypes";

const setBanksActionBefore = () => ({
    type: SET_BANKS_ACTION_BEFORE,
});

const setBanksActionFinished = () => ({
    type: SET_BANKS_ACTION_FINISH,
});

const setBanks = banks => ({
    type: SET_BANKS,
    payload: { banks },
});

export const setSelectedBank = bank => ({
    type: SET_SELECTED_BANK,
    payload: { bank },
});

export const resetBank = () => ({
    type: RESET_BANK
});

export const getBanks = () => {
    return dispatch => {

        dispatch(setBanksActionBefore());

        return axios({
            url: ApiUrls.BANKS,
            method: Methods.GET,
            params: {
                languageCode: LanguageUtils.getSelectedLanguage()?.toUpperCase?.()
            },
        })
            .then(({ data: { value: banks } }) => {
                dispatch(setBanks(banks.sort((a, b) => a.orderNumber - b.orderNumber)));
                dispatch(setBanksActionFinished());
            })
            .catch((ex) => {
                dispatch(setBanksActionFinished());
            });
    }
}

/** Bank Form */

const setBankFormActionBefore = () => ({
    type: SET_BANK_FORM_ACTION_BEFORE,
});

const setBankFormActionFinished = () => ({
    type: SET_BANK_FORM_ACTION_FINISH,
});

export const setBankForm = forms => ({
    type: SET_BANK_FORM,
    payload: { forms },
});

export const setBankTranslations = translations => ({
    type: SET_BANK_TRANSLATIONS,
    payload: { translations },
});

export const getBankForm = id => {
    return dispatch => {

        dispatch(setBankFormActionBefore());

        return axios({
            url: ApiUrls.BANK_FORM,
            method: Methods.GET,
            params: {
                id
            },
        })
            .then(({ data: { value: form } }) => {
                dispatch(setBankForm(form.forms));
                dispatch(setBankTranslations(form.translations));
                dispatch(setBankFormActionFinished());
            })
            .catch((ex) => {
                dispatch(setBankFormActionFinished());
            });
    }
}


/** Bank Details */

const setBankDetailsActionBefore = () => ({
    type: SET_BANK_DETAILS_ACTION_BEFORE,
});

const setBankDetailsActionFinished = () => ({
    type: SET_BANK_DETAILS_ACTION_FINISH,
});

const setBankDetails = details => ({
    type: SET_BANK_DETAILS,
    payload: { details },
});

const setBankDetailsError = error => ({
    type: SET_BANK_DETAILS_ERROR,
    payload: { error },
});

export const updateBankDetails = details => ({
    type: UPDATE_BANK_DETAILS,
    payload: { details },
});

export const getBankDetails = (id, amount) => {
    return dispatch => {

        dispatch(setBankDetailsActionBefore());

        return axios({
            url: ApiUrls.BANK_DETAILS,
            method: Methods.GET,
            params: {
                id,
                amount,
                languageCode: getLanguage()
            },
        })
            .then(({ data: { value: details } }) => {
                if(details.agentId || ( isPreviewMode() && !isPreviewModeForAgent() ) ){
                    dispatch(setBankDetails(details));
                } else {
                    if(details.maxAmount){
                        dispatch(setBankDetailsError({
                            resource: "MaxAmount",
                            maxAmount: details.maxAmount
                        }))
                    } else {
                        dispatch(setBankDetailsError({
                            resource: "Currency",
                        }))
                    }

                }
                dispatch(setBankDetailsActionFinished());
            })
            .catch(err => {
                const errorData = err?.response?.data ?? null;
                if(errorData.resource === "Agent_Bank" && errorData.resultCode === RESPONSE_CODES.NotAllowed){
                    dispatch(setBankDetailsError({
                        resource: "ShouldHaveDeposit"
                    }))
                } else {
                    dispatch(setBankDetailsError({
                        resource: errorData.resource,
                    }))
                }

                dispatch(setBankDetailsActionFinished());
            });
    }
}

export const getBankAgentDetails = (agentId, bankId) => {
    return dispatch => {

        dispatch(setBankDetailsActionBefore());

        return axios({
            url: ApiUrls.BANK_AGENT_DETAILS,
            method: Methods.GET,
            params: {
                agentId,
                bankId,
                languageCode: getLanguage()
            },
        })
            .then(({ data: { value: details } }) => {
                if(details.agentId || ( isPreviewMode() && !isPreviewModeForAgent() ) ){
                    dispatch(setBankDetails(details));
                } else {
                    if(details.maxAmount){
                        dispatch(setBankDetailsError({
                            resource: "MaxAmount",
                            maxAmount: details.maxAmount
                        }))
                    } else {
                        dispatch(setBankDetailsError({
                            resource: "Currency",
                        }))
                    }

                }
                dispatch(setBankDetailsActionFinished());
            })
            .catch(err => {
                const errorData = err?.response?.data ?? null;
                dispatch(setBankDetailsError({
                    resource: errorData.resource,
                }))
                dispatch(setBankDetailsActionFinished());
            });
    }
}
