import { useEffect } from "react";

/** Function - hook
	  * @function
	  * @param { object } ref - reference to element
      * @param { object } preventElementRef - reference to element, clicking on it should not call callback
	  * @param { func } handler - function to call when clicked outside
	  * @memberOf useOutsideClick
 */
const useOutsideClick = (ref, preventElementRef, handler) => {
	useEffect(() => {
		/** Function to detect if click is outside component
			* @function
			* @param { object } event - click event object
			* @memberOf useOutsideClick
		*/
		const listener = (event) => {
			if (!ref.current || ref.current.contains(event.target)) {
				return;
			}
            if (preventElementRef.current && preventElementRef.current.contains(event.target)) {
				return;
			}
			handler(event);
		};

		/** Add events listener */
		document.addEventListener("mousedown", listener);
		document.addEventListener("touchstart", listener);
		return () => {
			/** Remove events listener */
			document.removeEventListener("mousedown", listener);
			document.removeEventListener("touchstart", listener);
		};
	}, [ref, handler]);
}

export default useOutsideClick;