export const FORM_CONTROL_RULE = {
    REQUIRED: 1 << 0,
    OPTIONAL: 1 << 1,
    HIDDEN: 1 << 2,
}

export const FORM_CONTROL_TYPE = {
    INPUT: 1,
    SELECT: 2,
    PASSWORD: 3,
    DATEPICKER: 4,
    UPLOAD: 5
}

export const FORM_PREVALIDATION_RULE = {
    NONE: 0,
    REGEXP: 1
}