import axios from "axios"

import { initTranslations } from "translations/config";

import { setMessage } from "store/actions/common.actions"

import { getLanguage } from "utils/auth";

import ApiUrls from "constants/api.constants";
import Methods from "constants/httpMethods.constants";
import { PAYMENT_TYPE, TRANSACTION_TYPE } from "constants/common.constants";
import { RESPONSE_CODES } from "constants/errorCode.constants";

import {
	SET_SESSION,
	SET_SESSION_LOADED,
	SET_SESSION_EXPIRED,
	SET_INFO
} from "store/actionTypes";

export const setSession = session => ({
	type: SET_SESSION,
	payload: { session },
});

const setSessionLoaded = loaded => ({
	type: SET_SESSION_LOADED,
	payload: { loaded },
});

const setInfo = info => ({
	type: SET_INFO,
	payload: { info },
});

export const setSessionExpired = () => ({
	type: SET_SESSION_EXPIRED
})

const getDefaultData = () => {
	const urlParams = new URLSearchParams(window.location.search);
	return {
		id: urlParams.get("id"),
		paymentType: urlParams.get("PaymentType") || PAYMENT_TYPE.BANK_TRANSFER,
		transactionType: urlParams.get("TransactionType") || TRANSACTION_TYPE.DEPOSIT,
		languageCode: getLanguage(),
		amount: urlParams.get("Amount") ? Number(urlParams.get("Amount")) : undefined
	}
}

export const launch = () => {
	return (dispatch) => {
		const urlParams = new URLSearchParams(window.location.search);

		const projectId = urlParams.get("ProjectId")
		const token = urlParams.get("Token");

		const d = getDefaultData();

		if (!projectId || !token) {
			return axios({
				url: ApiUrls.LAUNCH_WIDGET,
				method: Methods.GET,
				params: d,
			})
				.then(({ status, data }) => {
					if (status < 400) {
						const iframeURL = new URL(data.value);
						const projectId = iframeURL.searchParams.get("ProjectId");
						const token = iframeURL.searchParams.get("Token");
						if (projectId && token) {
							dispatch(authenticate(projectId, token));
						}
					}
				})
				.catch((err) => {
					initTranslations(
						null,
						getLanguage(),
						() => {
							dispatch(setSessionLoaded(true));
							const errorData = err?.response?.data ?? null;
							if (errorData) {
								dispatch(setMessage({
									type: "error",
									message: errorData?.message ?? "",
									status: errorData?.resultCode ?? 0,
									resource: errorData?.resource ?? ""
								}))
							} else {
								dispatch(setMessage({
									type: "error",
									message: "SOMETHING WENT WRONG!",
									status: RESPONSE_CODES.SomethingWentWrong
								}))
							}
						}
					);
				});
		} else {
			dispatch(authenticate(projectId, token));
		}
	};
};

export const authenticate = (projectId, token) => {

	return (dispatch) => {
		return axios({
			url: ApiUrls.AUTHENTICATE,
			method: Methods.POST,
			data: { projectId, token },
		})
			.then(({ status, data }) => {
				if (status < 400) {
					const session = data?.value ?? {};
					dispatch(setSession({ ...session }));
					dispatch(getInfo());
				} else {
					initTranslations(
						null,
						getLanguage(),
						() => {
							dispatch(setSessionLoaded(true));
						}
					);
				}
			})
			.catch(() => {
				initTranslations(
					null,
					getLanguage(),
					() => {
						dispatch(setMessage({
							type: "error",
							message: "SOMETHING WENT WRONG!",
							status: RESPONSE_CODES.SomethingWentWrong
						}))
						dispatch(setSessionLoaded(true));
					}
				);
			});
	};
};

export const getInfo = () => {

	return dispatch => {

		return axios({
			url: ApiUrls.INFO,
			method: Methods.GET,
			params: {
				languageCode: getLanguage()
			}
		})
			.then(({ status, data }) => {
				if (status < 400) {
					const info = { ...(data?.value ?? {}) };
					dispatch(setInfo(info));

					const onSuccess = () => {
						dispatch(setSessionLoaded(true))
					};

					initTranslations(
						info.translationPath,
						getLanguage(),
						onSuccess
					);
				} else {
					dispatch(setSessionLoaded(true));
				}
			})
			.catch(() => {
				dispatch(setSessionLoaded(true));
			});
	};
};

