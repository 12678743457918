import { useContext, useState } from "react";

import { FormContext } from "../context";

import useFirstRender from "./useFirstRender";

const useFormItem = ({ 
    name, 
    dependencies, 
    rules 
}) => {
    const { formInstance: { _INTERNAL_ }, initialValues } = useContext(FormContext);

    const [value, setValue] = useState(initialValues?.[name]);

    const [validationState, setValidationState] = useState({
        isVisible: false,
        message: "",
    });

    useFirstRender({
        callBackFn: () => {
            const fieldStoreData = {
                value,
                setValueFn: setValue,
                fieldName: name,
            };

            if (Array.isArray(rules)) {
                fieldStoreData.validationData = {
                    setValidationFn: setValidationState,
                    rules,
                    message: null,
                };
            }

            if (Array.isArray(dependencies)) {
                fieldStoreData.dependencies = dependencies;
            }

            _INTERNAL_.createFieldStore(fieldStoreData);
        },
    });

    return {
        value,
        validationState,
        _INTERNAL_
    };
};

export default useFormItem;