import React from 'react';
import PropTypes from 'prop-types';

import NoDataImg from "assets/images/noData.svg"

/** Empty Component */
const Empty = ( {
    message,
    description
} ) => {

    return (
        <div className="rt--empty rt--flex rt--flex-col rt--justify-center rt--align-center ">
            <img alt="No Data" src={NoDataImg} />
            <b className="rt--title rt--font-bold rt--font-normal rt--mb-6 rt--mt-16">{message}</b>
            <span className="rt--title rt--font-regular rt--font-normal">{description}</span>
        </div>
    )
}

/** Empty propTypes
    * PropTypes
*/
Empty.propTypes = {
    /** Message */
    message: PropTypes.string,
    /** Message */
    description: PropTypes.string
}


export default Empty;