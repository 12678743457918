import React from "react";
import PropTypes from "prop-types";

import { FormContext } from "./context";

import useForm from "./hooks/useForm";

const Form = ({
    children,
    form,
	initialValues,
	onFinish,
    onFinishFailed,
}) => {
    const [formInstance] = useForm(form);
    
	const handleFormSubmit = (e) => {
		e.preventDefault();

		formInstance
			.validateFields()
			.then(values => {
				if (typeof onFinish === "function") {
					try {
						onFinish(values);
					} catch (error) {
						// Should print error if `onFinish` callback failed
						console.error(error);
					}
				}
			})
			.catch((validationsMessageList) => {
				if (typeof onFinishFailed === "function") {
					onFinishFailed(validationsMessageList);
				}
			});
	};

    return (
		<form
            className="rt--form" 
            onSubmit={handleFormSubmit} 
        >
			<FormContext.Provider value={{ formInstance, initialValues }}>
				{children}
			</FormContext.Provider>
		</form>
	);
}

/** Form propTypes
 * PropTypes
 */
Form.propTypes = {
	/** Items to render */
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
	/** Form instance generated in parent component */
	form: PropTypes.object,
	/** Form items initial values (will be set by item name)*/
	initialValues: PropTypes.object,
	/** Fires when the form is submitted and all validations are passed */
	onFinish: PropTypes.func,
    /** Fires when a form is submitted but at least one validation fails */
	onFinishFailed: PropTypes.func,
};

export default Form;