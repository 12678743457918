import React from 'react';

import PropTypes from "prop-types";

import { useTranslation } from 'react-i18next';

import { numberWithSpaces } from "utils/common";

/** Amount Component */
const Amount = ({
    currencyCode,
    amount
}) => {

    const { t } = useTranslation();

    return (

        <div className="rt--amount rt--mb-16 rt--flex rt--align-center rt--justify-between rt--pl-16 rt--pr-16">
            <span className='rt--title rt--font-big rt--font-regular'>{t("widget.common.amount")}:</span>
            <b className='rt--title rt--font-big rt--font-bold'>{`${numberWithSpaces(amount)} ${currencyCode}`}</b>
        </div>
    )
}

/** Amount propTypes
	* PropTypes
*/

Amount.propTypes = {
    /** Currency code */
    currencyCode: PropTypes.string,
    /** Amount */
    amount: PropTypes.number
};

export default Amount;