import { useRef } from "react";

import { asyncValidator, getValidationMessageList } from "../utils/utils";

function ConstructFormInstance() {
    const STORE = {
        // fieldName: {
        //     value,
        //     setValueFn,
        //     validationData: {
        //         rules: [],
        //         setValidationFn,
        //         message
        //     }
        //     dependencies
        // }
    };

    //-------------------------------FORM API----------------------------------//

    function setFieldsValue(fieldsValueObj) {
        if (!fieldsValueObj) {
            throw new Error(
                "setFieldsValue function must receive object that contains filed name as a key and newValue as a value"
            );
        }

        for (const [fieldName, newValue] of Object.entries(fieldsValueObj)) {
            if (!STORE[fieldName]) {
                throw new Error(
                    `The Form.Item named "${fieldName}" does not exist in Form, correct the name or remove it`
                );
            }

            storeMutationFnsObj.updateFieldValue({
                fieldName,
                value: newValue,
            });

            if (STORE[fieldName].validationData) {
                storeMutationFnsObj.updateFieldValidation({
                    fieldName,
                    value: newValue,
                });
            }

            if (STORE[fieldName].dependencies) {
                storeMutationFnsObj.updateFieldDependencies(
                    STORE[fieldName].dependencies
                );
            }
        }
    }

    function getFieldsValue(fieldsName) {
        // fieldsName -> pattern: [ fields name ] or nothing to get all values
        const fieldsValueObj = {};

        if (Array.isArray(fieldsName)) {
            fieldsName.forEach((fieldName) => {
                fieldsValueObj[fieldName] = STORE[fieldName].value;
            });

            return fieldsValueObj;
        }

        for (const [fieldName, fieldStore] of Object.entries(STORE)) {
            const fieldValue = fieldStore.value;

            fieldsValueObj[fieldName] = fieldValue;
        }

        return fieldsValueObj;
    }

    function getFieldValue(fieldName) {
        if (!fieldName) {
            return;
        }

        return STORE[fieldName].value;
    }

    async function validateFields() {
        for (const [fieldName, fieldStore] of Object.entries(STORE)) {
            if(!fieldStore.validationData) {
                continue;
            }

            await storeMutationFnsObj.updateFieldValidation({
                fieldName,
                value: fieldStore.value,
            });
        }

        const validationMessageList = getValidationMessageList(STORE);

        if (validationMessageList.length === 0) {
            return getFieldsValue();
        }

        throw validationMessageList;
    }

    //-------------------------------INTERNAL----------------------------------//

    const storeMutationFnsObj = {
        createFieldStore: function ({
            fieldName,
            value,
            setValueFn,
            validationData = null,
            dependencies = null,
        }) {
            if (fieldName === undefined) {
                return;
            }

            STORE[fieldName] = {
                value,
                setValueFn,
                validationData,
                dependencies,
            };
        },
        updateFieldValue: function ({ fieldName, value }) {
            const fieldData = STORE[fieldName];
            fieldData.value = value;
            fieldData.setValueFn(value);
        },
        updateFieldValidation: async function ({ fieldName, value }) {
            // Don't change reference
            const fieldValidationData = STORE[fieldName].validationData;

            await asyncValidator({
                rules: fieldValidationData.rules,
                formInstance: {
                    setFieldsValue,
                    getFieldsValue,
                    getFieldValue,
                    validateFields,
                },
                value,
            })
                .then(() => {
                    fieldValidationData.message = null;

                    // Do not reset message for animation
                    fieldValidationData.setValidationFn((prevState) => {
                        return {
                            ...prevState,
                            isVisible: false,
                        };
                    });
                })
                .catch((message) => {
                    fieldValidationData.message = message;

                    fieldValidationData.setValidationFn((prevState) => {
                        return {
                            ...prevState,
                            isVisible: true,
                            message,
                        };
                    });

                });
        },
        updateFieldDependencies: function (dependencies) {
            dependencies.forEach((fieldName) => {
                if (!STORE[fieldName]) {
                    throw new Error(
                        `The Form.Item named "${fieldName}" does not exist in Form, correct the name or remove it`
                    );
                }

                const fieldValue = STORE[fieldName].value;

                if (!fieldValue) {
                    return;
                }

                this.updateFieldValidation({
                    fieldName,
                    value: fieldValue,
                });
            });
        },
    };

    //------------------------------RETURN INSTANCE--------------------------------//

    this.setFieldsValue = setFieldsValue;
    this.getFieldsValue = getFieldsValue;
    this.getFieldValue = getFieldValue;
    this.validateFields = validateFields;
    this._INTERNAL_ = { ...storeMutationFnsObj };
}

const useForm = (form) => {
    const formInstanceRef = useRef();
    if (!formInstanceRef.current) {
        formInstanceRef.current = form ? form : new ConstructFormInstance();
    }

    return [formInstanceRef.current];
};

export default useForm;
