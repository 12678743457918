import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";

import FormId from "components/ui/formId"

import { PAYMENT_TYPE } from 'constants/common.constants';

import infoType from 'types/info.type';

/** Request Success Component */
const RequestSuccess = ({
    data,
    info
}) => {

    const { t } = useTranslation();

    return (
        <div className="rt--message" data-type="success">
            <div className="rt--message-header rt--flex rt--align-center rt--justify-center">
                <span className='rt--title rt--font-bold rt--font-big'>
                    {t("widget.common.requestDone")}
                </span>
            </div>
            <div className="rt--message-content  rt--flex rt--flex-col rt--align-center rt--justify-center rt--pb-34 rt--pt-34 rt--pl-24 rt--pr-24">
                <span className='rt--title rt--font-normal rt--font-regular'>{t("widget.common.paymentSuccess")}</span>
                {
                    info.paymentType === PAYMENT_TYPE.BANK_TRANSFER ? (
                        <div className='rt--mt-12'>
                            <FormId id={data.id} text={t("widget.common.transactionId")} />
                        </div>
                    ) : (
                        <div className='rt--mt-12'>
                            <FormId id={data.verificationCode} text={t("widget.common.code")} />
                        </div>
                    )
                }

            </div>
        </div>
    )
}

/** RequestSuccess propTypes
    * PropTypes
*/
RequestSuccess.propTypes = {
    /** Redux state property, Transaction data */
    data: PropTypes.object,
    /** Redux state property, session info */
    info: infoType
};

const mapStateToProps = (state) => {
    return {
        data: state.transaction.data,
        info: state.auth.info
    };
};

export default connect(mapStateToProps, null)(RequestSuccess);