import {
    SET_SESSION,
    SET_SESSION_LOADED,
    SET_SESSION_EXPIRED,
    SET_INFO
} from "store/actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_SESSION:
            return {
                ...state,
                session: payload.session
            }
        case SET_SESSION_LOADED:
            return {
                ...state,
                sessionLoaded: true
            }
        case SET_SESSION_EXPIRED:
            return {
                ...state,
                sessionExpired: true
            }
        case SET_INFO:
            return {
                ...state,
                info: payload.info
            }
        default:
            return state
    }
}